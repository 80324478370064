export enum FriendStatus {
	NoFriend = "NoFriend",
	Requested = "Requested",
	Pending = "Pending",
	Friend = "Friend",
}

export interface User {
	name: string;
	description: string;
	profilePicture: string;
	banner: string;
	friendStatus?: FriendStatus;
	friends?: User[];
	isConnected?: boolean;
}