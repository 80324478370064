import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";

function LoadingPage () {
	return (
		<Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
			<CircularProgress color="secondary"/>
			<Typography variant="h6">Chargement...</Typography>
		</Stack>
	);
}

export default LoadingPage;