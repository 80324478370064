import React from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

type AlertType = "success" | "warning" | "error" | "info";

export interface AlertOptions {
	text: string;
	type: AlertType;
	open: boolean;
}

const defaultOptions: AlertOptions = {
	text: "",
	type: "info",
	open: false
};

interface IProps {
	options?: AlertOptions;
	onClose: () => void;
}

export default function Alert ({ options, onClose }: IProps) {
	const { text, type, open } = options || defaultOptions;

	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
			<MuiAlert variant="filled" severity={type} onClose={onClose}>
				{text}
			</MuiAlert>
		</Snackbar>
	);
}

export function useAlertOption () {
	const [options, setOptions] = React.useState<AlertOptions>(defaultOptions);

	const open = (text: string, type: AlertType) => {
		setOptions({
			text,
			type,
			open: true
		});
	};

	const close = () => {
		setOptions(opt => ({
			...opt,
			open: false
		}));
	};

	return {
		alertOptions: options,
		openAlert: open,
		closeAlert: close
	};
}