import axios from "axios";
import { GameConfiguration } from "models/game/Game";
import { GameResponse, GameLobbiesListResponse, GameInfosResponse, GamesListResponse } from "models/requestsResponses/GameResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { AxiosConfigurator } from "./AxiosConfigurator";

const config = AxiosConfigurator.config;

/** 
 * Defines game service 
 */
export class GameService {
	/**
	 *  Gets the list of available games
	 * 
	 * @returns {Promise<GamesListResponse>} The list of available games
	 */
	static async getGamesList () {
		const response = await axios.get("/game", config);
		return response.data as GamesListResponse;
	}

	/** 
	 * Gets a game details by name
	 * 
	 * @param {string} gameName The name of the game
	 * @returns {Promise<GameInfosResponse>} The game details
	 */
	static async getGameByName (gameName: string) {
		const response = await axios.get(`/game/${gameName}`, config);
		return response.data as GameInfosResponse;
	}

	/** 
	 * Creates a new game 
	 *
	 * @param {string} gameName The name of the game
	 * @param {GameConfiguration} gameConfig The game configuration
	 * @returns {Promise<GameResponse>} The created game
	 */
	static async createGame (gameName: string, gameConfig: GameConfiguration) {
		const response = await axios.post("/game", {
			name: gameName,
			config: gameConfig
		}, config);
		return response.data as GameResponse;
	}

	/** 
	 * Joins a game
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The id of the room
	 * @returns {Promise<GameResponse>} The joined game 
	 */
	static async joinGame (gameName: string, roomId: string) {
		const response = await axios.post("/game/join", {
			name: gameName,
			roomId: roomId
		}, config);
		return response.data as GameResponse;
	}

	/** 
	 * Leaves a game
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The id of the room
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async leaveGame (gameName: string, roomId: string) {
		const response = await axios.post("/game/leave", {
			name: gameName,
			roomId: roomId
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Invites players to join a game
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The id of the room
	 * @param {string[]} players The list of players
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async invitePlayers (gameName: string, roomId: string, players: string[]) {
		const response = await axios.post("/game/invite", {
			gameName,
			roomId,
			players
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Kicks a player from a game
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The id of the room
	 * @param {string} playerName The name of the player to kick
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async kickPlayer (gameName: string, roomId: string, playerName: string) {
		const response = await axios.post("/game/kick", {
			gameName,
			roomId,
			playerName
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Starts the game
	 * 
	 * @param {string} name The name of the game
	 * @param {string} roomId The id of the room
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async startGame (name: string, roomId: string) {
		const response = await axios.post("/game/start", {
			name,
			roomId
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Replays a game
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The (finished) room id
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async replayGame (gameName: string, roomId: string) {
		const response = await axios.post("/game/replay", {
			name: gameName,
			roomId
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Update a game configuration
	 * 
	 * @param {string} name The name of the game
	 * @param {string} roomId The id of the room
	 * @param {GameConfiguration} gameConfig The game configuration
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async updateGameConfig (name: string, roomId: string, gameConfig: GameConfiguration) {
		const response = await axios.post("/game/updateConfig", {
			name,
			roomId,
			config: gameConfig
		}, config);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Gets available game lobbies
	 * 
	 * @param {string} gameName The name of the game
	 * @returns {Promise<GameLobbiesListResponse>} The game details 
	 */
	static async getGameLobbies (gameName: string) {
		const response = await axios.get(`/game/${gameName}/lobbies`, config);
		return response.data as GameLobbiesListResponse;
	}

	/**
	 * Sends a new message to the lobby
	 * 
	 * @param {string} gameName The name of the game
	 * @param {string} roomId The id of the room
	 * @param {string} msg The message to send
	 * @returns {Promise<RequestResponse<true>>} The response
	 */
	static async sendChatMessage (gameName: string, roomId: string, msg: string) {
		const response = await axios.post("/game/newMsg", {
			gameName,
			roomId,
			msg
		}, config);

		return response.data as RequestResponse<true>;
	}
}