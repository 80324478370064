import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { NotificationService } from "services/appApi/NotificationService";
import { NotifSocketService } from "services/socket/NotifSocketService";
import InvitationReceivedDialog from "pages/Game/InvitationReceivedDialog";
import { useLogged } from "./AuthProvider";
import { Notification, NotificationType } from "models/Notification";

const NotifContext = React.createContext<[Notification[], Dispatch<SetStateAction<Notification[]>>, Notification?]>([
	[],
	() => { /* */ },
	undefined
]);

interface IProps {
	children: React.ReactNode;
}

export function NotifProvider ({ children }: IProps) {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [lastReseived, setLastReseived] = useState<Notification>();

	const isUserLogged = useLogged();

	useEffect(() => {
		if (isUserLogged) {
			NotificationService.getNotifications()
				.then(data => {
					if (!data.isError) {
						setNotifications(data.value);
					}
				});

			const offNewNotification = NotifSocketService.onNewNotification(onNewNotification);

			return () => {
				offNewNotification();
			};
		}
	}, [isUserLogged]);

	const onNewNotification = (notif: Notification) => {
		setNotifications(notifications => [...notifications, notif]);
		setLastReseived(notif);
	};

	return (
		<NotifContext.Provider value={[notifications, setNotifications, lastReseived]}>
			<InvitationReceivedDialog />
			{children}
		</NotifContext.Provider>
	);
}

export function useNotifications (type: NotificationType) {
	const [allNotifs, setNotifications, lastReseived] = React.useContext(NotifContext);
	return [
		allNotifs.filter(notif => notif.type === type),
		setNotifications,
		lastReseived
	] as [Notification[], Dispatch<SetStateAction<Notification[]>>, Notification?];
}