import React, { useState, useLayoutEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "components/Page";
import Feeds from "./Feeds";
import FriendsList from "./FriendsList";
import GamesList from "./GamesList";
import { useChatList } from "components/providers/ChatListProvider";

const useStyles = makeStyles(() => ({
	sideMenu: {
		position: "fixed",
	}
}));

function HomePage () {
	const [sideMenuWidth, setSideMenuWidth] = useState<string | number>("auto");
	const [sideMenuHeight, setSideMenuHeight] = useState<string | number>("auto");

	const classes = useStyles();
	const sideMenuRef = React.useRef<HTMLDivElement>(null);

	const chatList = useChatList();

	useLayoutEffect(() => {
		setSideMenuWidth(sideMenuRef.current?.clientWidth || "auto");
		setSideMenuHeight(sideMenuRef.current?.clientHeight || "auto");

		chatList.setContainerRightOffset((sideMenuRef.current?.clientWidth || 0) + 10);
	}, [sideMenuRef]);

	return (
		<Page>
			<Grid container>
				<Grid item xs={3} lg={2} ref={sideMenuRef} component="div">
					<Paper className={classes.sideMenu} sx={{
						width: sideMenuWidth,
						height: sideMenuHeight,
					}}>
						<GamesList />
					</Paper>
				</Grid>
				<Grid item xs={6} lg={8}>
					<Feeds />
				</Grid>
				<Grid item xs={3} lg={2}>
					<Paper className={classes.sideMenu} sx={{
						width: sideMenuWidth,
						height: sideMenuHeight,
					}}>
						<FriendsList />
					</Paper>
				</Grid>
			</Grid>
		</Page>
	);
}

export default HomePage;