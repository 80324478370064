import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, useMediaQuery, Theme, Paper, Button } from "@mui/material";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { AuthService } from "services/appApi/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoMessage } from "models/InfoMessage";
import PasswordLostForm from "./PasswordLostForm";
import ResetPswForm from "./ResetPswForm";
import { useLogged } from "components/providers/AuthProvider";

interface LocationState {
	from?: string;
}

function LoginPage () {
	const [formToShow, setFormToShow] = useState<"login" | "signup" | "pswLost" | "resetPsw">("login");
	const [loginFormMsg, setLoginFormMsg] = useState<InfoMessage>();

	const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
	const location = useLocation();
	const navigate = useNavigate();
	const isLogged = useLogged();

	useEffect(() => {
		if (isLogged) {
			let to = "/";
			if (location.state) {
				const { from } = location.state as LocationState;
				if (from) {
					to = from;
				}
			}
			navigate(to);
		}
	}, [isLogged]);

	useEffect(() => {
		if (location.search) {
			const params = new URLSearchParams(location.search);
			const accounttActivated = params.get("activated");
			const userId = params.get("userId");
			if (accounttActivated === "true") {
				setLoginFormMsg({
					color: "green",
					message: "Votre compte a été activé avec succès. Vous pouvez maintenant vous connecter."
				});
			} else if (accounttActivated === "false") {
				setLoginFormMsg({
					color: "red",
					message: "Une erreur est survenue lors de l'activation de votre compte.",
					action: {
						label: "Renvoyer l'email de confirmation",
						onClick: () => handleSendConfirmationEmail(userId ? userId : "")
					}
				});
			}

		}
	}, []);

	useEffect(() => {
		if (location.search) {
			const params = new URLSearchParams(location.search);
			const resetForm = params.get("resetForm");
			if (resetForm === "true") {
				setFormToShow("resetPsw");
			}
		}
	}, []);

	const handleOnSignup = (email: string) => {
		setFormToShow("login");
		setLoginFormMsg({
			color: "green",
			message: "Votre compte a bien été créé ! Une email de confirmation vous a été envoyée.",
			action: {
				label: "Renvoyer l'email de confirmation",
				onClick: () => handleSendConfirmationEmail(email)
			}
		});
	};

	const handleSendConfirmationEmail = (emailOrId: string) => {
		AuthService.sendBackActivationUrl(emailOrId)
			.then(() => {
				setLoginFormMsg({
					color: "green",
					message: "L'email de confirmation a bien été renvoyé.",
					action: {
						label: "Renvoyer l'email de confirmation",
						onClick: () => handleSendConfirmationEmail(emailOrId)
					}
				});
			});
	};

	const handleOnResetPsw = () => {
		setFormToShow("login");
		setLoginFormMsg({
			color: "green",
			message: "Votre mot de passe a bien été changé. Vous pouvez maintenant vous connecter."
		});
	};

	const handleSwitchLoginSignupForm = () => {
		if (formToShow === "login") {
			setFormToShow("signup");
		} else {
			setFormToShow("login");
		}
	};

	const handleOnPasswordLost = () => {
		setFormToShow("pswLost");
	};

	const getForm = () => {
		switch (formToShow) {
			case "signup":
				return (
					<SignupForm onSignup={handleOnSignup} />
				);
			case "pswLost":
				return (
					<PasswordLostForm />
				);
			case "resetPsw":
				return (
					<ResetPswForm onReset={handleOnResetPsw} />
				);
			default:
				return (
					<LoginForm onPswLostClick={handleOnPasswordLost} infoMessage={loginFormMsg} />
				);
		}
	};

	return (
		<Container maxWidth="md">
			<Grid
				container
				direction={largeScreen ? "row" : "column"}
				alignItems="center"
				justifyContent="center"
				spacing={10}
				style={{ minHeight: "100vh" }}
			>
				<Grid item xs={5}>
					<Typography variant="h3">Gamesnet</Typography>
					<Typography variant="h6">Partagez, gardez le contact et jouez à des jeux de sociétés avec vos amis</Typography>
				</Grid>
				<Grid item xs={7}>
					<Grid container direction="column" spacing={3}>
						<Grid item>
							{getForm()}
						</Grid>
						<Grid item>
							<Paper elevation={3}>
								<Typography textAlign="center">
									{formToShow === "login" ? "Vous n'avez pas de compte ?" : "Vous avez déjà un compte ?"}
									<Button variant="text" color="secondary" onClick={handleSwitchLoginSignupForm}>
										{formToShow === "login" ? "Inscrivez vous !" : "Connectez vous !"}
									</Button>
								</Typography>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}

export default LoginPage;