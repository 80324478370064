import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "components/providers/AuthProvider";
import Router from "components/Navigation/Router";
import { AxiosConfigurator } from "services/appApi/AxiosConfigurator";
import { ConsoleUtils } from "utils/ConsoleUtils";
import { NotifProvider } from "components/providers/NotifProvider";
import ChatListProvider from "components/providers/ChatListProvider";
import { BrowserRouter } from "react-router-dom";
import PopupAlertProvider from "components/providers/PopupAlertProvider";

const palette = {
	type: "dark",
	primary: {
		main: "#595959",
	},
	secondary: {
		light: "#fff",
		main: "#f0f0f0",
		dark: "#a8a8a8",
		contrastText: "#000"
	},
	background: {
		paper: "#464646",
		default: "#303031",
	},
	text: {
		primary: "#e6e6e6",
		secondary: "rgba(255,255,255,0.8)",
	},
	action: {
		disabledBackground: "#868686",
		disabled: "#D8DBE0"
	},
	paper: "#292929",
	divider: "#a8a8a8"
};

const theme = createTheme({
	palette,
	components: {
		MuiPaper: {
			defaultProps: {
				style: {
					padding: 10
				},
			}
		},
		MuiOutlinedInput: {
			defaultProps: {
				sx: {
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: palette.secondary.dark
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: palette.secondary.main,
					}
				}
			},
		},
	}
});

function App () {
	useEffect(() => {
		AxiosConfigurator.configure();
		ConsoleUtils.disableConsoleInProd();
	}, []);

	return (
		<AuthProvider>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<NotifProvider>
						<PopupAlertProvider>
							<ChatListProvider>
								<CssBaseline/>
								<Router />
							</ChatListProvider>
						</PopupAlertProvider>
					</NotifProvider>
				</BrowserRouter>
			</ThemeProvider>
		</AuthProvider>
	);
}

export default App;
