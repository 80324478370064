import React from "react";
import { Avatar, MenuItem, Paper, Stack, Typography } from "@mui/material";
import IconMenu from "components/IconMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Comment } from "models/Post";

interface IProps {
	item: Comment;
	isOwner: boolean;
	onDeleteComment: (commentId: number) => void;
}

function PostComment ({ item, isOwner, onDeleteComment }: IProps) {
	const handleDeleteComment = () => {
		onDeleteComment(item.id);
	};

	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Avatar src={item.authorProfilePicture} />
			<Paper
				elevation={12}
				sx={{
					width: "100%",
					px: "25px!important",
					py: 1,
				}}
			>
				<Stack direction="row" justifyContent="space-between">
					<Stack spacing={1}>
						<Typography variant="body1">{item.authorName}</Typography>
						<Typography variant="body2">{item.content}</Typography>
					</Stack>
					{isOwner && (
						<IconMenu
							id={`post-comment-menu-${item.id}`}
							icon={<MoreHorizIcon color="secondary" />}
						>
							<MenuItem onClick={handleDeleteComment}>
									Supprimer
							</MenuItem>
						</IconMenu>
					)}
				</Stack>
			</Paper>
		</Stack>
	);
}

export default PostComment;