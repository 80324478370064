import { GridPosition } from "models/game/TicTacToe/TicTacToeModels";
import { GameSocketService } from "../GameSocketService";

/** 
 * Define the socket service for the TicTacToe game. 
 */
export class TicTacToeSocketService {
	/**
	 * Plays a turn and places symbol on the grid.
	 * 
	 * @param {string} roomId The room id
	 * @param {GridPosition} position The position on the grid where the symbol will placed
	 */
	static playTurn (roomId: string, position: GridPosition) {
		GameSocketService.playTurn(roomId, { position });
	}
}