import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import IconMenu from "components/IconMenu";
import ForumSharpIcon from "@mui/icons-material/ForumSharp";
import { Badge, Box, Divider, MenuItem, Theme } from "@mui/material";
import { useNotifications } from "components/providers/NotifProvider";
import { Notification, NotificationType } from "models/Notification";
import { NotificationService } from "services/appApi/NotificationService";
import NotifItem from "./NotifItem";
import { useNavigate } from "react-router-dom";
import { useChatList } from "components/providers/ChatListProvider";

interface IStylesProps {
	iconSize: number;
}

const useStyles = makeStyles<Theme, IStylesProps>(theme => ({
	iconContainer: {
		borderRadius: "50%",
		backgroundColor: theme.palette.secondary.main,
		height: props => props.iconSize - 7,
		width: props => props.iconSize - 7,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	badge: {
		"& .MuiBadge-badge": {
			right: 3,
			top: 5,
		},
	}
}));

interface IProps {
	iconSize: number;
}

function MessageNotifMenu ({ iconSize }: IProps) {
	const [messageNotifs, setNotifs, lastReseived] = useNotifications(NotificationType.NewMessage);

	const classes = useStyles({ iconSize });
	const navigate = useNavigate();
	const chatList = useChatList();

	useEffect(() => {
		if (lastReseived && chatList.isOpen(lastReseived.value)) {
			NotificationService.deleteNotification(lastReseived.id);
			setNotifs(notifs => notifs.filter(notif => notif.id !== lastReseived.id));
		}
	}, [lastReseived]);

	const handleOpenChat = (notif: Notification) => {
		NotificationService.deleteNotification(notif.id);
		setNotifs(notifs => notifs.filter(n => n.id !== notif.id));

		chatList.openChatByGroupId(parseInt(notif.value));
	};

	const getMenuItems = () => {
		const items: JSX.Element[] = [];
		let i = 0;

		for (const notif of messageNotifs) {
			if (i > 0) {
				items.push(<Divider key={`friend-notif-divider-${notif.id}`} />);
			}

			items.push(<MenuItem
				key={`message-notif-${notif.id}`}
			>
				<NotifItem
					text={`${notif.sender.name} envoyé vous un message`}
					mainAction={{
						label: "Lire",
						onClick: () => handleOpenChat(notif)
					}}
					avatar={notif.sender.profilePicture}
					onAvatarClick={() => navigate(`/user/${notif.sender.name}`)}
				/>
			</MenuItem>);

			i++;
		}

		return items;
	};

	return (
		<IconMenu
			id="message-notif-menu"
			icon={
				<Badge
					className={classes.badge}
					badgeContent={messageNotifs.length}
					color="error"
				>
					<Box className={classes.iconContainer}>
						<ForumSharpIcon
							sx={{
								color: theme => theme.palette.primary.dark,
							}}
						/>
					</Box>
				</Badge>
			}
		>
			{getMenuItems()}
		</IconMenu>
	);
}

export default MessageNotifMenu;