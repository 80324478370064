import { Utils } from "utils/utils";
import { GameConfiguration } from "./Game";

export interface ConfigParam {
	name: string;
	type: "text" | "number" | "select";
	defaultValue?: string | number | boolean | object;
	value?: string | number | boolean | object;
}

export type DefaultConfigType = { [k: string]: ConfigParam[] }
export const defaultGameConfig: DefaultConfigType = {
	Morpion: [
		{
			name: Utils.propertyOf<GameConfiguration>("minPlayers"),
			type: "number",
			value: 2,
		},
		{
			name: Utils.propertyOf<GameConfiguration>("maxPlayers"),
			type: "number",
			value: 2,
		}
	]
};