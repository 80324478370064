import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
	children: JSX.Element;
	requiredStates: string[];
	redirectPageOnFail: string;
}

function RequireState ({ children, requiredStates, redirectPageOnFail }: IProps) {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!location.state) {
			accessDenied();
			return;
		}

		const state = location.state as object;
		const isInvalidState = requiredStates.some(s => !(s in state));

		if (isInvalidState) {
			accessDenied();
		}

	}, [location.state, requiredStates]);

	const accessDenied = () => navigate(redirectPageOnFail);

	const renderedChildren = React.Children.map(children, function (child: React.ReactElement) {
		return React.cloneElement(child, {
			...location.state as object,
		});
	});

	return (<>{renderedChildren}</>);
}

export default RequireState;