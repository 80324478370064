/* eslint-disable camelcase */
import React, { useState } from "react";
import Picker, { IEmojiData } from "emoji-picker-react";
import { IconButton, Menu } from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

interface IProps {
	onEmojiClick: (emoji: string) => void;
}

function EmojiPicker ({ onEmojiClick }: IProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleEmojiClick = (e: React.MouseEvent<Element, MouseEvent>, emojiData: IEmojiData) => {
		handleClose();
		onEmojiClick(emojiData.emoji);
	};

	return (
		<div>
			<IconButton
				color="secondary"
				size="small"
				aria-controls={open ? "emoji-btn-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleOpen}
			>
				<EmojiEmotionsIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="emoji-menu"
				open={open}
				sx={{
					"& .MuiMenu-list": {
						padding: 0
					}
				}}
				onClose={handleClose}
			>
				<Picker
					disableSearchBar
					disableSkinTonePicker
					native
					preload
					pickerStyle={{
						backgroundColor: "inherit",
					}}
					groupNames={{
						smileys_people: "Smileys & Personnages",
						animals_nature: "Animaux & Nature",
						food_drink: "Nourriture & Boissons",
						travel_places: "Voyages & Lieux",
						activities: "Activités",
						objects: "Objets",
						symbols: "Symboles",
						flags: "Drapeaux",
						recently_used: "Utilisés récemment",
					}}
					onEmojiClick={handleEmojiClick}
				/>
			</Menu>
		</div>
	);
}

export default EmojiPicker;
