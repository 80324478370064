import React from "react";
import { Avatar, MenuItem, Paper, Stack, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconMenu from "components/IconMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { MessageData } from "models/Chat";

const useStyles = makeStyles(() => ({
	avatar: {
		width: "30px !important",
		height: "30px !important",
	}
}));

interface IProps {
	item: MessageData
	isOwner: boolean;
	isGameChat?: boolean;
	onDelete: (id: number | string) => void;
}

function MessageItem ({ item, isOwner, isGameChat, onDelete }: IProps) {
	const classes = useStyles();
	const theme = useTheme();

	const handleDeleteMsg = () => {
		onDelete(item.id);
	};

	return (
		<Stack direction={isOwner ? "row" : "row-reverse"} alignItems="center" p={1} spacing={1}>
			<Avatar className={classes.avatar} src={item.sender.profilePicture} />
			<Paper
				elevation={0}
				sx={{
					backgroundColor: theme.palette.primary.dark,
					p: 1
				}}
			>
				<Stack spacing={1}>
					<Stack direction={isOwner ? "row" : "row-reverse"} justifyContent="space-between" spacing={3}>
						<Typography variant="body1">{item.sender.name}</Typography>
						{isOwner && !isGameChat && (
							<IconMenu
								id={`chat-${"chatId"}-menu-${1}`}
								icon={<MoreHorizIcon color="secondary" />}
								sx={{
									p: 0,
									m: 0
								}}
							>
								<MenuItem onClick={handleDeleteMsg}>
									Supprimer
								</MenuItem>
							</IconMenu>
						)}
					</Stack>
					<Typography variant="body2">{item.msg}</Typography>
				</Stack>
			</Paper>

		</Stack>
	);
}

export default MessageItem;