import React from "react";
import { MenuItem, Stack, TextField } from "@mui/material";
import { Utils } from "utils/utils";
import { GameConfiguration, GameVisibility } from "models/game/Game";

interface IProps {
	config?: Partial<GameConfiguration>;
	readonly?: boolean;
	onConfigChange: (prop: string, value: string | number | boolean) => void;
}

function ConfigForm ({ config, readonly, onConfigChange }: IProps) {

	const handleConfigChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onConfigChange(e.target.name, e.target.value);
	};

	const handleMinMaxPlayerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const propName = e.target.name;
		const value = parseInt(e.target.value);

		if (propName === Utils.propertyOf<GameConfiguration>("minPlayers") && config?.maxPlayers && value > config?.maxPlayers) {
			return;
		}

		if (propName === Utils.propertyOf<GameConfiguration>("maxPlayers") && config?.minPlayers && value < config?.minPlayers) {
			return;
		}

		if (value > 0) {
			onConfigChange(propName, value);
		}
	};

	return (
		<Stack spacing={2} mt={1}>
			<TextField
				name={Utils.propertyOf<GameConfiguration>("visibility")}
				select
				label="Visibilité"
				variant="outlined"
				color="secondary"
				size="small"
				value={config?.visibility ?? ""}
				sx={{ minWidth: 200 }}
				disabled={readonly}
				onChange={handleConfigChange}
			>
				<MenuItem value={GameVisibility.PUBLIC}>Publique</MenuItem>
				<MenuItem value={GameVisibility.PRIVATE}>Privée</MenuItem>
			</TextField>
			<TextField
				name={Utils.propertyOf<GameConfiguration>("minPlayers")}
				type="number"
				label="Nombre min de joueurs"
				variant="outlined"
				color="secondary"
				size="small"
				value={config?.minPlayers ?? ""}
				fullWidth
				disabled={readonly}
				onChange={handleMinMaxPlayerChange}
			/>
			<TextField
				name={Utils.propertyOf<GameConfiguration>("maxPlayers")}
				type="number"
				label="Nombre max de joueurs"
				variant="outlined"
				color="secondary"
				size="small"
				value={config?.maxPlayers ?? ""}
				fullWidth
				disabled={readonly}
				onChange={handleMinMaxPlayerChange}
			/>
		</Stack>
	);
}

export default ConfigForm;