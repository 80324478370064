import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUser } from "components/providers/AuthProvider";
import { PostService } from "services/appApi/PostService";
import { Post } from "models/Post";
import PostList from "components/Post/PostList";
import PostForm from "./PostForm";
import NoPostImg from "assets/images/noPost.png";

const useStyles = makeStyles(() => ({
	container: {
		height: "calc(100vh - 86px)",
		"&::-webkit-scrollbar": {
			width: "0.4em"
		},
		"&::-webkit-scrollbar-track": {
			"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,.1)",
			outline: "1px solid slategrey",
			borderRadius: "10px"
		}
	}
}));

function Feeds () {
	const [posts, setPosts] = useState<Post[]>([]);
	const [hasMorePosts, setHasMorePosts] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const user = useUser();

	const classes = useStyles();

	useEffect(() => {
		fetchPosts(1);
	}, []);

	const fetchPosts = (page: number) => {
		if (!isLoading) {
			setIsLoading(true);
			PostService.getFeeds(page, 10)
				.then(data => {
					setIsLoading(false);
					if (!data.isError) {
						setPosts(posts => [...posts, ...data.value.posts]);
						setHasMorePosts(data.value.totalPages !== page);
					}
				})
				.catch(() => setIsLoading(false));
		}
	};

	const emptyPost: Post = {
		id: -1,
		authorName: user?.name ?? "Gamesnet",
		authorProfilePicture: user?.profilePicture,
		textContent: "Aucun de vos amis n'a publié de contenu pour le moment.",
		mediaUrl: NoPostImg,
		mediaType: "image",
		isLiked: false,
		likeCount: 0,
		comments: [],
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<PostForm />
			<Box my={2}>
				<PostList
					posts={posts}
					hasMore={hasMorePosts}
					isLoading={isLoading}
					emptyPost={emptyPost}
					onLoadPosts={fetchPosts}
					onSetPost={setPosts}
				/>
			</Box>
		</Container>

	);
}

export default Feeds;