import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { RequestResponse } from "models/requestsResponses/RequestResponse";

/**
 * Defines global axios configuration
 */
export class AxiosConfigurator {
	static config: AxiosRequestConfig = {
		baseURL: process.env.REACT_APP_SERVER_URL,
		withCredentials: true,
		headers: {
			"Content-Type": "application/json",
			"Accept": "*/*; charset=utf-8",
		}
	};

	static formDataConfig: AxiosRequestConfig = {
		...AxiosConfigurator.config,
		headers: {
			...AxiosConfigurator.config.headers,
			"Content-Type": "multipart/form-data"
		}
	};

	/**
	 *  Configures Axios
	 */
	static configure () {
		Axios.interceptors.response.use(
			(response: AxiosResponse) => response,
			(({ response }: { response: AxiosResponse<RequestResponse<false>> }) => {
				if (response?.data?.isError) {
					console.error(response.data.message);
					return Promise.reject(response.data.message);
				} else {
					return Promise.reject(response);
				}
			})
		);
	}
}