import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Backdrop, Box, Button, Divider, IconButton, MenuItem, Paper, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfigForm from "./ConfigForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Alert, { useAlertOption } from "components/Alert";
import { useDisclosure } from "hooks/customHooks";
import { usePopupAlert } from "components/providers/PopupAlertProvider";
import IconMenu from "components/IconMenu";
import { useUser } from "components/providers/AuthProvider";
import { GameService } from "services/appApi/GameService";
import { GameSocketService } from "services/socket/Game/GameSocketService";
import { Game, GameConfiguration, GameStatus, Player } from "models/game/Game";
import { defaultGameConfig } from "models/game/DefaultConfig";
import InviteDialog from "./InviteDialog";

const useStyles = makeStyles((theme: Theme) => ({
	accordion: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		"&:before": {
			opacity: "0 !important",
		},
	}
}));

interface IProps {
	game: Game;
	onGameUpdated: (game: Game | undefined) => void;
	onBack: () => void;
}

function Lobby ({ game, onGameUpdated, onBack }: IProps) {
	const [editedConfig, setEditedConfig] = useState<Partial<GameConfiguration>>();
	const [saveConfigLoading, setSaveConfigLoading] = useState(false);
	const [startGameLoading, setStartGameLoading] = useState(false);
	const [isInviteDialogOpen, openInviteDialog, closeInviteDialog] = useDisclosure();

	const classes = useStyles();
	const { alertOptions, openAlert, closeAlert } = useAlertOption();
	const popupAlert = usePopupAlert();
	const user = useUser();
	const navigate = useNavigate();
	const gameStatusRef = useRef<GameStatus>(GameStatus.WAITING);

	const { name, roomId, config, players, roomMasterId } = game;
	const playersCount = players.length;
	const roomMaster = players.find(p => p.id === roomMasterId);
	const isRoomMaster = user?.name === roomMaster?.name;
	const isFull = playersCount >= config.maxPlayers;

	const defaultConfig = defaultGameConfig[name];

	useEffect(() => {
		setEditedConfig(config);
	}, [config]);

	useEffect(() => {
		const unsubGameUpdate = GameSocketService.onGameUpdate(onGameUpdate);
		const unsubKickedFromGame = GameSocketService.onKickedFromGame(onKickedFromGame);

		return () => {
			unsubGameUpdate();
			unsubKickedFromGame();
		};
	}, [name, roomId]);

	useEffect(() => {
		gameStatusRef.current = game.status;
		if (game.status === GameStatus.PLAYING) {
			navigate(`/game/${game?.name}/play`, { state: { initialGame: game } });
		}
	}, [game.status]);

	useEffect(() => {
		return () => {
			if (gameStatusRef.current === GameStatus.WAITING) {
				GameService.leaveGame(name, roomId);
			}
		};
	}, []);

	const onGameUpdate = (game: Game) => {
		setEditedConfig(game.config);
		onGameUpdated(game);
	};

	const onKickedFromGame = () => {
		onGameUpdated(undefined);
		popupAlert.open({
			title: "Vous avez été exclu du jeu",
			message: "Vous avez été exclu du jeu par l'hôte de la partie.",
			color: "info",
		});
	};

	const handleSetConfigProp = (prop: string, value: string | number | boolean) => {
		const configParam = defaultConfig.find(p => p.name === prop);
		if (configParam?.value) {
			return;
		}

		setEditedConfig(config => ({
			...config,
			[prop]: value
		}));
	};

	const handleSaveConfig = () => {
		setSaveConfigLoading(true);
		GameService.updateGameConfig(name, roomId, editedConfig as GameConfiguration)
			.then(data => {
				setSaveConfigLoading(false);
				if (!data.isError) {
					openAlert("Configuration mis à jour", "success");
				}
			})
			.catch(err => {
				let errMsg = "Une erreur est survenue !";
				if (typeof err === "string") {
					errMsg = err;
				}

				openAlert(errMsg, "error");
				setEditedConfig(config);
				setSaveConfigLoading(false);
			});
	};

	const handleInvitePlayers = (players: string[]) => {
		if (players.length > 0) {
			GameService.invitePlayers(name, roomId, players)
				.then(data => {
					if (!data.isError) {
						openAlert("Invitation envoyée", "success");
					}
				})
				.catch(err => {
					let errMsg = "Une erreur est survenue !";
					if (typeof err === "string") {
						errMsg = err;
					}

					openAlert(errMsg, "error");
				});
		}
	};

	const handleKickPlayer = (playerName: string) => {
		if (isRoomMaster) {
			GameService.kickPlayer(name, roomId, playerName)
				.then(data => {
					if (!data.isError) {
						openAlert(`${playerName} a été exclu !`, "success");
					}
				})
				.catch(err => {
					let errMsg = "Une erreur est survenue !";
					if (typeof err === "string") {
						errMsg = err;
					}

					openAlert(errMsg, "error");
				});
		}
	};

	const handleStartGame = () => {
		setStartGameLoading(true);
		GameService.startGame(name, roomId)
			.then(() => setStartGameLoading(false))
			.catch(err => {
				let errMsg = "Une erreur est survenue !";
				if (typeof err === "string") {
					errMsg = err;
				}

				setStartGameLoading(false);
				openAlert(errMsg, "error");
			});
	};

	return (
		<Paper elevation={3}>
			<Alert
				options={alertOptions}
				onClose={closeAlert}
			/>
			<InviteDialog
				loggedUserName={user?.name || ""}
				open={isInviteDialogOpen}
				lobbyUsers={players.map(p => p.name)}
				onInvite={handleInvitePlayers}
				onClose={closeInviteDialog}
			/>
			<Stack direction="row" alignItems="center" spacing={2}>
				<Tooltip title="Quitter le salon">
					<IconButton onClick={onBack}>
						<ArrowBackIcon color="secondary" />
					</IconButton>
				</Tooltip>
				<Typography variant="h6">{name} - Salon de {roomMaster?.name}</Typography>
			</Stack>
			<Divider />
			<Accordion className={classes.accordion} disableGutters>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon color="secondary" />}
				>
					<Typography>Configuration</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Stack spacing={2}>
						<ConfigForm
							config={editedConfig}
							readonly={!isRoomMaster}
							onConfigChange={handleSetConfigProp}
						/>
						{
							isRoomMaster && (
								<LoadingButton
									variant="contained"
									loading={saveConfigLoading}
									onClick={handleSaveConfig}
								>
									Sauvegarder
								</LoadingButton>
							)
						}
					</Stack>
				</AccordionDetails>
			</Accordion>
			<Box>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography variant="h6">{`Joueurs (${playersCount})`}</Typography>
					{
						!isFull && (
							<Button
								variant="text"
								color="secondary"
								onClick={openInviteDialog}
							>
                Inviter un ami
							</Button>
						)
					}
				</Stack>
				<Divider />
				<Stack mt={1} direction="row" justifyContent="space-evenly" alignItems="center" flexWrap="wrap" spacing={4}>
					{
						players.map(player => (
							<LobbyPlayer
								key={player.name}
								player={player}
								isRoomMaster={isRoomMaster}
								isOwnProfile={user?.name === player.name}
								onKick={handleKickPlayer}
							/>
						))
					}
				</Stack>
				<Box mt={3}>
					<LoadingButton
						variant="contained"
						fullWidth
						disabled={!isFull || !isRoomMaster}
						loading={startGameLoading}
						onClick={handleStartGame}
					>
						{playersCount >= game.config.minPlayers ?
							"Commencer la partie" :
							`En attente de ${game.config.minPlayers - playersCount} joueur(s)`}
					</LoadingButton>
				</Box>
			</Box>
		</Paper>
	);
}

interface ILobbyPlayerProps {
	player: Player;
	isRoomMaster: boolean;
	isOwnProfile: boolean;
	onKick: (playerName: string) => void;
}

function LobbyPlayer ({ player, isRoomMaster, isOwnProfile, onKick }: ILobbyPlayerProps) {
	const [isHover, setIsHover] = useState(false);

	const handleOpenMenu = () => setIsHover(true);
	const handleCloseMenu = () => setIsHover(false);
	const handleKick = () => onKick(player.name);

	return (
		<Stack spacing={1} alignItems="center" my={1}>
			<Box
				position="relative"
				onMouseEnter={handleOpenMenu}
				onMouseLeave={handleCloseMenu}
			>
				<Avatar src={player.profilePicture} />
				{
					!isOwnProfile && (
						<Backdrop
							open={isHover}
							sx={{
								position: "absolute",
								width: "100%",
								height: "100%",
								borderRadius: "50%",
							}}
						>
							<IconMenu
								id={`player-menu-${player.name}`}
								icon={<MoreHorizIcon color="secondary" />}
								sx={{}}
							>
								<MenuItem>
									<Link
										to={`/user/${player.name}`}
										target="_blank"
										rel="noopener noreferrer"
										style={{
											textDecoration: "none",
											color: "inherit",
										}}
									>
								Voir le profil
									</Link>
								</MenuItem>
								{
									isRoomMaster && (
										<MenuItem onClick={handleKick}>Exclure</MenuItem>
									)
								}
							</IconMenu>
						</Backdrop>
					)
				}
			</Box>
			<Typography fontWeight="bold">{player.name}</Typography>
		</Stack>
	);
}

export default Lobby;