import { SocketService } from "../SocketService";
import { SocketMessages } from "models/socket/SocketMessages";
import { Game } from "models/game/Game";
import { GameInvitation } from "models/game/GameInvitation";

/** 
 * Defines the global game socket service 
 */
export class GameSocketService {
	/**
	 * Subscribe to GAME_UPDATE event message
	 * 
	 * @param {(game: Game) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onGameUpdate (action: (game: Game) => void) {
		SocketService.on(SocketMessages.GAME_UPDATE, action);

		return () => SocketService.off(SocketMessages.GAME_UPDATE, action);
	}

	/**
	 * Subscribe to INVITED_TO_GAME event message
	 * 
	 * @param {(invitation: GameInvitation) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onInvitedToGame (action: (invitation: GameInvitation) => void) {
		SocketService.on(SocketMessages.INVITED_TO_GAME, action);

		return () => SocketService.off(SocketMessages.INVITED_TO_GAME, action);
	}

	/**
	 * Subscribe to KICKED_FROM_GAME event message
	 * 
	 * @param {() => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onKickedFromGame (action: () => void) {
		SocketService.on(SocketMessages.KICKED_FROM_GAME, action);

		return () => SocketService.off(SocketMessages.KICKED_FROM_GAME, action);
	}

	/**
	 * Subscribe to REPLAY_GAME event message
	 * 
	 * @param {(game: Game) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onReplayGame (action: (game: Game) => void) {
		SocketService.on(SocketMessages.REPLAY_GAME, action);

		return () => SocketService.off(SocketMessages.REPLAY_GAME, action);
	}

	/**
	 * Subscribe to GAME_STOP_ERROR event message
	 * 
	 * @param {() => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onGameStopedWithError (action: (error: string) => void) {
		SocketService.on(SocketMessages.GAME_STOP_ERROR, action);

		return () => SocketService.off(SocketMessages.GAME_STOP_ERROR, action);
	}

	/**
	 * Plays a turn
	 * 
	 * @param {string} roomId The room id
	 * @param {object} data The data to send
	 */
	static playTurn (roomId: string, data: object) {
		SocketService.emit(SocketMessages.USER_PLAY, {
			roomId,
			...data
		});
	}
}