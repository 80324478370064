import React, { useState, useEffect } from "react";
import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDisclosure } from "hooks/customHooks";
import NewGameDialog from "./NewGameDialog";
import { GameLobbyData } from "models/game/GameLobbyData";
import { Game } from "models/game/Game";
import { GameService } from "services/appApi/GameService";

interface IProps {
	gameName?: string;
	onCreateGame: (game: Game) => void;
	onJoinGame: (roomId: string) => void;
}

function LobbiesList ({ gameName, onCreateGame, onJoinGame }: IProps) {
	const [lobbies, setLobbies] = useState<GameLobbyData[]>([]);
	const [isNewGameDialogOpen, openNewGameDialog, closeNewGameDialog] = useDisclosure();

	useEffect(() => {
		getLobbies();

		const interval = setInterval(getLobbies, 15000);

		return () => {
			clearInterval(interval);
		};
	}, [gameName]);

	const getLobbies = () => {
		if (gameName) {
			GameService.getGameLobbies(gameName)
				.then(data => {
					if (!data.isError) {
						setLobbies(data.value);
					}
				})
				.catch(() => {
					setLobbies([]);
				});
		}
	};

	return (
		<TableContainer component={Paper}>
			<NewGameDialog
				gameName={gameName ?? ""}
				isOpen={isNewGameDialogOpen}
				onCreateGame={onCreateGame}
				onClose={closeNewGameDialog}
			/>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h6">Parties disponibles</Typography>
				<Button variant="contained" size="small" onClick={openNewGameDialog}>Créer une partie</Button>
			</Stack>
			<Table aria-label="Parties">
				<TableHead>
					<TableRow>
						<TableCell align="center">Partie</TableCell>
						<TableCell align="center">Joueurs</TableCell>
						<TableCell align="center">Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						lobbies.map(lobby => (
							<TableRow key={lobby.roomId}>
								<TableCell align="center">{lobby.lobbyName}</TableCell>
								<TableCell align="center">{`${lobby.playerCount} / ${lobby.maxPlayers}`}</TableCell>
								<TableCell align="center">
									<Button
										variant="contained"
										size="small"
										disabled={lobby.playerCount >= lobby.maxPlayers}
										onClick={() => onJoinGame(lobby.roomId)}
									>
														Rejoindre
									</Button>
								</TableCell>
							</TableRow>
						))
					}
				</TableBody>
			</Table>
			{
				lobbies.length === 0 && (
					<Typography textAlign="center">Aucune partie disponible</Typography>
				)
			}
		</TableContainer>
	);
}

export default LobbiesList;