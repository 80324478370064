export interface Game {
	name: string;
	status: GameStatus;
	roomId: string;
	config: GameConfiguration;
	players: Player[];
	roomMasterId: number;
	turnPlayerName: string;
	winner?: string | null;
}

export interface GameConfiguration {
	visibility: GameVisibility;
	minPlayers: number;
	maxPlayers: number;
}

export interface Player {
	id: number;
	name: string;
	profilePicture?: string;
	isConnected: boolean;
}

export enum GameStatus {
	WAITING = "WAITING",
	PLAYING = "PLAYING",
	FINISHED = "FINISHED"
}

export enum GameVisibility {
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE"
}