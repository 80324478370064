import React, { useState, useEffect } from "react";
import { AuthService } from "services/appApi/AuthService";
import { User } from "models/User";
import { UserResponse } from "models/requestsResponses/UserResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { SocketService } from "services/socket/SocketService";

interface IProps {
	children: React.ReactNode
}

interface AuthContextType {
	user?: User,
	firstLoginLoading: boolean,
	login: (login: string, psw: string) => Promise<UserResponse>,
	loginWithCookie: () => void,
	logout: () => Promise<RequestResponse<true>>,
}

const AuthContext = React.createContext<AuthContextType>({
	login: async () => ({
		isError: true,
		message: "",
	}),
	loginWithCookie: () => { /* */ },
	logout: async () => ({
		isError: true,
		message: ""
	}),
	firstLoginLoading: true,
});

export function AuthProvider ({ children }: IProps) {
	const [user, setUser] = useState<User>();
	const [firstLoginLoading, setFirstLoginLoading] = useState(true);

	useEffect(() => {
		if (user) {
			SocketService.connect();
		}

		return () => {
			SocketService.disconnect();
		};
	}, [user]);

	const login = async (login: string, psw: string) => {
		const data = await AuthService.login(login, psw);
		if (!data.isError) {
			setUser(data.value);
		}
		setFirstLoginLoading(false);
		return data;
	};

	const loginWithCookie = () => {
		AuthService.loginWithCookie()
			.then(data => {
				if (!data.isError) {
					setUser(data.value);
				}
			})
			.finally(() => setFirstLoginLoading(false));
	};

	const logout = async () => {
		const data = await AuthService.logout();
		if (!data.isError) {
			setUser(undefined);
			window.location.reload();
		}
		return data;
	};

	const value = {
		user,
		login,
		loginWithCookie,
		logout,
		firstLoginLoading
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
}

export function useAuth () {
	return React.useContext(AuthContext);
}

export function useUser () {
	const auth = useAuth();
	return auth.user;
}

export function useLogged () {
	const user = useUser();
	return user ? true : false;
}