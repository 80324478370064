import React from "react";
import IconMenu from "components/IconMenu";
import { Avatar, Divider, ListItemIcon, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

interface IProps {
	iconSize: number;
}

function ProfileMenu ({ iconSize }: IProps) {
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	const handleGoMyProfil = () => {
		navigate(`/user/${user?.name}`);
	};

	const handleGoSettings = () => {
		navigate("/settings");
	};

	return (
		<IconMenu
			id="profile-menu"
			icon={
				<Avatar
					src={user?.profilePicture}
					sx={{
						width: iconSize,
						height: iconSize,
					}}
				/>
			}
		>
			<MenuItem onClick={handleGoMyProfil}>
				<ListItemIcon>
					<AccountCircleIcon color="secondary" />
				</ListItemIcon>
        Mon profil
			</MenuItem>
			<MenuItem onClick={handleGoSettings}>
				<ListItemIcon>
					<SettingsIcon color="secondary" />
				</ListItemIcon>
        Paramètres
			</MenuItem>
			<Divider />
			<MenuItem onClick={logout}>
				<ListItemIcon>
					<LogoutIcon color="secondary" />
				</ListItemIcon>
        Déconnexion
			</MenuItem>
		</IconMenu>
	);
}

export default ProfileMenu;