import React, { useState } from "react";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Chat, { IChatProps } from "components/Chat/Chat";
import { ChatService } from "services/appApi/ChatService";

interface ChatContextType {
	setContainerRightOffset: (offset: number) => void;
	isOpen: (groupId: number | string) => boolean;
	openChat: (chat: IChatProps) => void;
	openChatByGroupId: (groupId: number) => void;
	closeChat: (roomName: string) => void;
}

const ChatContext = React.createContext<ChatContextType | undefined>(undefined);

const useStyles = makeStyles(() => ({
	container: ({right}: {right: number}) => ({
		position: "fixed",
		bottom: 0,
		right,
		zIndex: 1,
	})
}));

interface IProps {
	children: React.ReactNode
}

function ChatListProvider ({ children }: IProps) {
	const [containerRightOffset, setContainerRightOffset] = useState(0);
	const [chatList, setChatList] = useState<IChatProps[]>([]);

	const classes = useStyles({ right: containerRightOffset });

	const isOpen = (groupId: string | number) => {
		return chatList.some(chat => chat.groupData.id.toString() === groupId.toString());
	};

	const openChat = (chat: IChatProps) => {
		if (!isOpen(chat.groupData.id)) {
			setChatList([...chatList, chat]);
		}
	};

	const openChatByGroupId = (groupId: number) => {
		if (!isOpen(groupId)) {
			ChatService.getGroupById(groupId)
				.then(data => {
					if (!data.isError) {
						const chat: IChatProps = {
							groupData: data.value,
							title: data.value.name,
							members: data.value.members,
							events: {
								onGetMessages: ChatService.getMessages,
								onSendMessage: ChatService.sendMessage,
								onDeleteMessage: ChatService.deleteMessage,
							}
						};
						setChatList([...chatList, chat]);
					}
				});
		}
	};

	const closeChat = (roomName: string) => {
		setChatList(chatList => chatList.filter(c => c.groupData.name !== roomName));
	};

	const providerValue: ChatContextType = {
		setContainerRightOffset,
		isOpen,
		openChat,
		openChatByGroupId,
		closeChat,
	};

	return (
		<ChatContext.Provider value={providerValue}>
			{children}
			<Stack
				className={classes.container}
				direction="row-reverse"
				alignItems="flex-end"
				spacing={2}
			>
				{
					chatList.map((chatProps, inx) => (
						<Chat key={inx} {...chatProps} />
					))
				}
			</Stack>
		</ChatContext.Provider>
	);
}

export function useChatList () {
	const context = React.useContext(ChatContext);

	if (context === undefined) {
		throw new Error("useChatListProvider must be used within a ChatListProvider");
	}

	return context;
}

export default ChatListProvider;