import React from "react";
import Box from "@mui/material/Box";

interface IProps {
	children: React.ReactNode;
	value: number;
	index: number;
}

function TabPanel ({ children, value, index, ...other }: IProps) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					{children}
				</Box>
			)}
		</div>
	);
}

export default TabPanel;