import React, { useState } from "react";
import { Container, Paper, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import EditProfileForm from "./EditProfileForm";
import Page from "components/Page";
import ChangePswForm from "./ChangePswForm";

function SettingsPage () {
	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	return (
		<Page title="Paramètres">
			<Container maxWidth="md" sx={{
				mt: 2,
				display: "flex",
				justifyContent: "center",
			}}>
				<Paper elevation={3} sx={{ width: "100%" }}>
					<Tabs
						sx={{ p: 2}}
						value={tabValue}
						textColor="secondary"
						indicatorColor="secondary"
						onChange={handleTabChange}
					>
						<Tab label="Profil" />
						<Tab label="Changer de mot de passe" />
					</Tabs>
					<TabPanel value={tabValue} index={0}>
						<EditProfileForm />
					</TabPanel>
					<TabPanel value={tabValue} index={1}>
						<ChangePswForm />
					</TabPanel>
				</Paper>
			</Container>
		</Page>
	);
}

export default SettingsPage;