import React from "react";
import { Routes, Route } from "react-router-dom";
import { useLogged } from "components/providers/AuthProvider";
import RequireAuth from "./RequireAuth";
import RequireState from "./RequireState";
import Header from "components/Header/Header";
import LoginPage from "pages/Login/LoginPage";
import HomePage from "pages/Home/HomePage";
import ProfilPage from "pages/Profile/ProfilePage";
import SettingsPage from "pages/Settings/SettingsPage";
import GamePage from "pages/Game/GamePage";
import TicTacToePage from "pages/Game/AllGames/TicTacToe/TicTacToePage";
import GameCatalogPage from "pages/GameCatalog/GameCatalogPage";

function Router () {
	const isUserLogged = useLogged();

	return (
		<React.Fragment>
			{ isUserLogged && <Header /> }
			<Routes>
				<Route index element={<RequireAuth><HomePage /></RequireAuth>} />
				<Route path="login" element={<LoginPage />} />
				<Route path="user">
					<Route path=":userName" element={<RequireAuth><ProfilPage /></RequireAuth>} />
				</Route>
				<Route path="settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
				<Route path="game">
					<Route index element={<RequireAuth><GameCatalogPage /></RequireAuth>} />
					<Route path=":gameName" element={<RequireAuth><GamePage /></RequireAuth>} />
					<Route path="Morpion/play" element={
						<RequireAuth>
							<RequireState requiredStates={["initialGame"]} redirectPageOnFail="/game/Morpion">
								<TicTacToePage />
							</RequireState>
						</RequireAuth>
					} />
				</Route>
			</Routes>
		</React.Fragment>
	);
}

export default Router;