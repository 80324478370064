import axios from "axios";
import { GetChatGroupResponse, GetMessagesListResponse } from "models/requestsResponses/ChatResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { AxiosConfigurator } from "./AxiosConfigurator";

const config = AxiosConfigurator.config;

/**
 * Defines the chat service
 */
export class ChatService {
	/**
	 * Gets the common chat group with the given user
	 * 
	 * @param {string } userName The user name to get the common chat group with
	 * @returns {Promise<GetChatGroupResponse>} The chat group
	 */
	static async getCommonGroup (userName: string) {
		const response = await axios.post("/chat/getcommongroup", {
			userName
		}, config);
		return response.data as GetChatGroupResponse;
	}

	/**
	 * Get chat group by id
	 * 
	 * @param {number} groupId The chat group id
	 * @returns {Promise<GetChatGroupResponse>} The chat group
	 */
	static async getGroupById (groupId: number) {
		const response = await axios.post("/chat/getgroupbyid", {
			id: groupId.toString()
		}, config);
		return response.data as GetChatGroupResponse;
	}

	/** 
	 * Gets the messages list for the given chat group
	 * 
	 * @param {number | string} groupId The chat group id 
	 * @param {number} page The items page number
	 * @param {number} itemPerPage The number of items per page
	 */
	static async getMessages (groupId: number| string, page: number, itemPerPage: number) {
		const response = await axios.post("/chat/getmessages", {
			id: groupId.toString(),
			page,
			itemPerPage
		}, config);
		return response.data as GetMessagesListResponse;
	}

	/** 
	 * Sends a message to the given chat group
	 * 
	 * @param {number | string} groupId The chat group id
	 * @param {string} message The message to send
	 * @returns {Promise<RequestResponse<true>>} The request response
	 */
	static async sendMessage (groupId: number | string, message: string) {
		const response = await axios.post("/chat/newmessage", {
			msg: message,
			groupId: groupId.toString()
		}, config);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Delete a message
	 * 
	 * @param {number | string} messageId The message to delete
	 * @returns {Promise<RequestResponse<true>>} The request response
	 */
	static async deleteMessage (messageId: number | string) {
		const response = await axios.post("/chat/deletemessage", {
			id: messageId
		}, config);
		return response.data as RequestResponse<true>;
	}
}