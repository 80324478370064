import { SocketService } from "./SocketService";
import { SocketMessages } from "models/socket/SocketMessages";
import { MessageData } from "models/Chat";

/**
 * Defines the chat socket service
 */
export class ChatSocketService {
	/**
	 * Subscribe to NEW_CHAT_MESSAGE event message
	 * 
	 * @param {(message: MessageData) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onNewMessage (action: (message: MessageData) => void) {
		SocketService.on(SocketMessages.NEW_CHAT_MESSAGE, action);

		return () => SocketService.off(SocketMessages.NEW_CHAT_MESSAGE, action);
	}

	/** 
	 * Subscribe to DELETE_CHAT_MESSAGE event message
	 * 
	 * @param {(message: MessageData) => void} action The action to execute when the message is received 
	 * @returns {() => void} Func to unsubscribe from the event
	 */
	static onDeleteMessage (action: (message: MessageData) => void) {
		SocketService.on(SocketMessages.DELETE_CHAT_MESSAGE, action);

		return () => SocketService.off(SocketMessages.DELETE_CHAT_MESSAGE, action);
	}
}