/**
 * Defines console utilities
 */
export class ConsoleUtils {
	/**
	 * Disables console in production
	 */
	static disableConsoleInProd () {
		/* eslint-disable jsdoc/require-jsdoc */
		/* eslint-disable @typescript-eslint/no-empty-function */

		if (process.env.NODE_ENV === "production") {
			console.log = function () { };
			console.warn = function () { };
			console.error = function () { };
		}
	}
}