import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GameService } from "services/appApi/GameService";
import { GameData } from "models/GameData";

function GamesList () {
	const [games, setGames] = useState<GameData[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		GameService.getGamesList()
			.then(data => {
				if (!data.isError) {
					setGames(data.value);
				}
			});
	}, []);

	const handleGotoGameCatalogPage = () => {
		navigate("/game");
	};

	const handleGotoGamePage = (gameName: string) => {
		navigate(`/game/${gameName}`);
	};

	return (
		<Stack spacing={2} height="100%">
			<Typography variant="h6" textAlign="center">Catalogue de jeux</Typography>
			<Divider />
			<List sx={{flex: 1}}>
				{games.map(game => (
					<ListItem key={game.name} disablePadding>
						<ListItemButton onClick={() => handleGotoGamePage(game.name)}>
							<ListItemAvatar>
								<Avatar src={game.icon} />
							</ListItemAvatar>
							<ListItemText primary={game.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Box justifySelf="flex-end" alignSelf="center">
				<Button variant="text" color="secondary" onClick={handleGotoGameCatalogPage}>Plus de jeux</Button>
			</Box>
		</Stack>
	);
}

export default GamesList;