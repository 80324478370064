import React, { useEffect, useState } from "react";
import { Avatar, Button, Grid, TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuth, useUser } from "components/providers/AuthProvider";
import { LoadingButton } from "@mui/lab";
import { UserService } from "services/appApi/UserService";
import Alert, { AlertOptions } from "components/Alert";

const useStyles = makeStyles((theme: Theme) => ({
	banner: {
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(2),
		height: "30vh",
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
}));

function EditProfileForm () {
	const [pictureFile, setPictureFile] = useState<File | null>(null);
	const [bannerFile, setBannerFile] = useState<File | null>(null);
	const [description, setDescription] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [alertOptions, setAlertOptions] = useState<AlertOptions>();

	const [pictureSrc, setPictureSrc] = useState<string | ArrayBuffer | null>(null);
	const [bannerSrc, setBannerSrc] = useState<string | ArrayBuffer | null>(null);

	const classes = useStyles();
	const user = useUser();
	const auth = useAuth();

	const pictureInputRef = React.createRef<HTMLInputElement>();
	const bannerInputRef = React.createRef<HTMLInputElement>();

	useEffect(() => {
		setDescription(user?.description ?? "");
	}, [user]);

	const handleCloseAlert = () => {
		setAlertOptions(options => ({
			text: options?.text || "",
			type: options?.type || "info",
			open: false,
		}));
	};

	const handleOpenPictureInput = () => {
		pictureInputRef.current?.click();
	};

	const handleOpenBannerInput = () => {
		bannerInputRef.current?.click();
	};

	const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}

		setPictureFile(e.target.files.item(0));

		const fr = new FileReader();
		fr.onload = function () {
			setPictureSrc(this?.result);
		};
		fr.readAsDataURL(e.target.files[0]);
	};

	const handleBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}

		setBannerFile(e.target.files.item(0));

		const fr = new FileReader();
		fr.onload = function () {
			setBannerSrc(this?.result);
		};
		fr.readAsDataURL(e.target.files[0]);
	};

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
	};

	const handleEditUser = () => {
		setIsLoading(true);
		UserService.editUser(description, pictureFile, bannerFile)
			.then(() => {
				setIsLoading(false);

				setAlertOptions({
					text: "Mise à jour effectuée avec succès",
					type: "success",
					open: true,
				});

				auth.loginWithCookie();
			})
			.catch(() => {
				setIsLoading(false);

				setAlertOptions({
					text: "Une erreur est survenue",
					type: "error",
					open: true,
				});
			});
	};

	return (
		<React.Fragment>
			<Grid container direction="column" spacing={3}>
				<Grid
					className={classes.banner}
					style={{ backgroundImage: `url(${bannerSrc ?? user?.banner})` }}
					container
					item
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Grid item>
						<Grid
							item
							container
							direction="column"
							alignItems="center"
							spacing={1}
						>
							<Grid item>
								<Avatar
									sx={{
										width: 100,
										height: 100,
									}}
									src={pictureSrc as string || user?.profilePicture}
								/>
							</Grid>
							<Grid item>
								<Button variant="outlined" color="secondary" onClick={handleOpenPictureInput}>
                Changer la photo
								</Button>
							</Grid>
							<input
								ref={pictureInputRef}
								type="file"
								style={{ display: "none" }}
								accept="image/*"
								onChange={handlePictureChange}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Button variant="outlined" color="secondary" onClick={handleOpenBannerInput}>
						Changer de bannière
						</Button>
						<input
							ref={bannerInputRef}
							type="file"
							style={{ display: "none" }}
							accept="image/*"
							onChange={handleBannerChange}
						/>
					</Grid>
				</Grid>

				<Grid item>
					<TextField
						name="description"
						variant="outlined"
						color="secondary"
						label="Description"
						multiline
						rows={3}
						fullWidth
						value={description}
						onChange={handleDescriptionChange}
					/>
				</Grid>

				<Grid item alignSelf="flex-end">
					<LoadingButton
						variant="contained"
						color="primary"
						loading={isLoading}
						onClick={handleEditUser}
					>
                    Sauvegarder
					</LoadingButton>
				</Grid>
			</Grid>
			<Alert
				options={alertOptions}
				onClose={handleCloseAlert}
			/>
		</React.Fragment>
	);
}

export default EditProfileForm;