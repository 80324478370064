import axios from "axios";
import { UserResponse } from "models/requestsResponses/UserResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { AxiosConfigurator } from "./AxiosConfigurator";

const config = AxiosConfigurator.config;

/** 
 * Defines user authentication service 
 */
export class AuthService {
	/**
	 * Register a new user
	 * 
	 * @param {string} name The user's name
	 * @param {string} email The user's email
	 * @param {string} psw The user's password
	 * @param {string} pswConfirm The password confirmation
	 * @param {string?} description The user's description
	 * @param {File?} profilePicture The user's profile picture
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async signup (
		name: string,
		email: string,
		psw: string,
		pswConfirm: string,
		description?: string,
		profilePicture?: File
	) {
		const formData = new FormData();
		formData.append("name", name);
		formData.append("email", email);
		formData.append("psw", psw);
		formData.append("pswConfirm", pswConfirm);
		if (description) {
			formData.append("description", description);
		}
		if (profilePicture) {
			formData.append("profilePicture", profilePicture);
		}

		const response = await axios.post("/auth/signup", formData, AxiosConfigurator.formDataConfig);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Send back an activation account url to the user
	 * 
	 * @param {string} emailOrId The user's email or id
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async sendBackActivationUrl (emailOrId: string) {
		const response = await axios.post("/auth/sendbackativationurl", {
			emailOrId
		}, config);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Log a user in
	 * 
	 * @param {string} login The user's login
	 * @param {string} psw The user's password
	 * @returns {Promise<UserResponse>} The user's response
	 */
	static async login (login: string, psw: string) {
		const response = await axios.post("/auth/login", {
			login,
			psw
		}, config);
		return response.data as UserResponse;
	}

	/**
	 * Log a user in with cookie token
	 * 
	 * @returns {Promise<UserResponse>} The response
	 */
	static async loginWithCookie () {
		const response = await axios.post("/auth/loginwithcookie", {}, config);
		return response.data as UserResponse;
	}

	/**
	 * Log a user out
	 * 
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async logout () {
		const response = await axios.post("/auth/logout", {}, config);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Change the user's password by the old one
	 * 
	 * @param {string} oldPsw The user's old password
	 * @param {string} psw The user's new password
	 * @param {string} pswConfirm The user's new password confirmation
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async changePassword (oldPsw: string, psw: string, pswConfirm: string) {
		const response = await axios.post("/auth/changepsw", {
			oldPsw,
			psw,
			pswConfirm
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Send a reset password email
	 *
	 * @param {string} email The user's email
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async sendResetPasswordEmail (email: string) {
		const response = await axios.post("/auth/sendresetpswemail", {
			email
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Reset a user's password
	 * 
	 * @param {string} userId The user id
	 * @param {string} resetToken The reset password token
	 * @param {string} psw The new password
	 * @param {string} pswConfirm The new password confirmation
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async resetPassword (userId: string, resetToken: string, psw: string, pswConfirm: string) {
		const response = await axios.post("/auth/resetpsw", {
			userId,
			resetToken,
			psw,
			pswConfirm
		}, config);
		return response.data as RequestResponse<true>;
	}
}