import React, { useState } from "react";
import { Typography, TextField, Paper, Grid, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AuthService } from "services/appApi/AuthService";
import { InfoMessage } from "models/InfoMessage";

function PasswordLostForm () {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [infoMessage, setInfoMessage] = useState<InfoMessage>();

	const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleReset = () => {
		setLoading(true);
		AuthService.sendResetPasswordEmail(email)
			.then(data => {
				setLoading(false);
				if (data.isError) {
					setErrors(data.message.split("\n"));
					return;
				}

				setErrors([]);
				setEmail("");
				setInfoMessage({
					color: "green",
					message: "Un email vous a été envoyé pour réinitialiser votre mot de passe.",
				});
			})
			.catch(err => {
				setLoading(false);

				if (err) {
					setErrors(err.split("\n"));
				} else {
					setErrors(["Une erreur est survenue !"]);
				}
			});
	};

	return (
		<Paper elevation={3}>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Typography textAlign="center" variant="h5">Mot de passe perdu ?</Typography>
					<Divider color="primary" />
				</Grid>
				{
					infoMessage && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: infoMessage.color }}>
								<Typography variant="body1">{infoMessage.message}</Typography>
								{
									infoMessage.action && (
										<LoadingButton
											variant="text"
											color="secondary"
											loading={loading}
											onClick={infoMessage.action.onClick}
										>
											{infoMessage.action.label}
										</LoadingButton>
									)
								}
							</Paper>
						</Grid>
					)
				}
				{
					errors && errors.length > 0 && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: "red" }}>
								<ul>
									{
										errors.map((error, inx) => (
											<li key={inx}>{error}</li>
										))
									}
								</ul>
							</Paper>
						</Grid>
					)
				}
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						autoFocus
						type="email"
						name="email"
						label="Votre email"
						value={email}
						onChange={onEmailChange}
					/>
				</Grid>
				<Grid item>
					<LoadingButton
						variant="contained"
						color="primary"
						fullWidth
						disabled={!email}
						loading={loading}
						onClick={handleReset}
					>
						Réinitialiser le mot de passe
					</LoadingButton>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default PasswordLostForm;