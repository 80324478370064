import { useState } from "react";

/**
 * Hook to open and close a thing
 * 
 * @returns {[boolean, () => void, () => void, () => void]} isOpen, open, close, toggle
 */
export function useDisclosure () {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);
	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	return [
		isOpen,
		open,
		close,
		toggle
	] as const;
}