import React, { useState } from "react";
import { Avatar,  Divider, Stack, Typography, List, ListItem, ListItemButton, ListItemAvatar, ListItemText } from "@mui/material";
import { useUser } from "components/providers/AuthProvider";
import { useChatList } from "components/providers/ChatListProvider";
import { User } from "models/User";
import { ChatService } from "services/appApi/ChatService";

function FriendsList () {
	const [isChatGroupLoading, setIsChatGroupLoading] = useState(false);

	const user = useUser();
	const { openChat } = useChatList();

	const handleOpenChat = (friend: User) => {
		if (user && !isChatGroupLoading) {
			setIsChatGroupLoading(true);
			ChatService.getCommonGroup(friend.name)
				.then(data => {
					setIsChatGroupLoading(false);
					if (!data.isError) {
						openChat({
							groupData: data.value,
							title: friend.name,
							members: [user, friend],
							events: {
								onGetMessages: ChatService.getMessages,
								onSendMessage: ChatService.sendMessage,
								onDeleteMessage: ChatService.deleteMessage,
							}
						});
					}
				})
				.catch(() => setIsChatGroupLoading(false));
		}
	};

	return (
		<Stack spacing={2}>
			<Typography variant="h6" textAlign="center">Mes amis</Typography>
			<Divider />
			<List>
				{user?.friends?.map(friend => (
					<ListItem key={friend.name} disablePadding>
						<ListItemButton onClick={() => handleOpenChat(friend)}>
							<ListItemAvatar>
								<Avatar src={friend.profilePicture} />
							</ListItemAvatar>
							<ListItemText primary={friend.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Stack>
	);
}

export default FriendsList;