import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Alert, { useAlertOption } from "components/Alert";
import { useNavigate } from "react-router-dom";
import { useUser } from "components/providers/AuthProvider";
import { GameService } from "services/appApi/GameService";
import { GameSocketService } from "services/socket/Game/GameSocketService";
import { Game, GameStatus } from "models/game/Game";

interface IProps {
	gameName: string;
	roomId: string;
	gameStatus: GameStatus
}

function ReplayGameDialog ({ gameName, roomId, gameStatus }: IProps) {
	const [loading, setLoading] = useState(false);

	const user = useUser();
	const { alertOptions, openAlert, closeAlert } = useAlertOption();
	const navigate = useNavigate();

	useEffect(() => {
		const unsubOnReplayGame = GameSocketService.onReplayGame(onReplayGame);

		return () => {
			unsubOnReplayGame();
		};
	}, []);

	const onReplayGame = (game: Game) => {
		if (game.players.some(p => p.name === user?.name)) {
			navigate(`/game/${game.name}`, { state: { game } });
		} else {
			navigate(`/game/${game.name}`, { state: { inviteRoom: game.roomId } });
		}
	};

	const handleClose = () => {
		navigate(`/game/${gameName}`);
	};

	const handleReplayGame = () => {
		setLoading(true);
		GameService.replayGame(gameName, roomId)
			.catch(err => {
				setLoading(false);

				let msg = "Une erreur est survenue !";
				if (typeof err === "string") {
					msg = err;
				}

				openAlert(msg, "error");
			});
	};

	return (
		<React.Fragment>
			<Alert options={alertOptions} onClose={closeAlert} />
			<Dialog
				open={GameStatus.FINISHED === gameStatus}
				onClose={handleClose}
			>
				<DialogTitle>Fin de la partie</DialogTitle>
				<DialogContent>
					<DialogContentText>{!loading ? "Voulez-vous rejouer une partie" : "En attente de l'hôte. Veuillez patienter..."}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="secondary" onClick={handleClose}>Non</Button>
					<LoadingButton variant="contained" loading={loading} onClick={handleReplayGame}>Oui</LoadingButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default ReplayGameDialog;