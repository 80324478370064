import React from "react";
import { Badge, Divider, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import IconMenu from "components/IconMenu";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "components/providers/NotifProvider";
import { Notification, NotificationType } from "models/Notification";
import { NotificationService } from "services/appApi/NotificationService";
import NotifItem from "./NotifItem";
import { UserService } from "services/appApi/UserService";

const useStyles = makeStyles(() => ({
	badge: {
		"& .MuiBadge-badge": {
			right: 3,
			top: 5,
		},
	}
}));

interface IProps {
	iconSize: number;
}

function FriendNotifMenu ({ iconSize }: IProps) {
	const [friendNotifs, setNotifs] = useNotifications(NotificationType.FriendRequest);

	const classes = useStyles();
	const navigate = useNavigate();

	const handleMarkAllAsRead = () => {
		const notifIds = friendNotifs.map(notif => notif.id);
		if (notifIds.length > 0) {
			NotificationService.markedAsRead(notifIds)
				.then(data => {
					if (!data.isError) {
						const readNotifs = friendNotifs.map(notif => ({
							...notif,
							read: true
						}));

						// Set all friend notifs as read
						setNotifs(notifs => {
							const copyNotifs = notifs.filter(notif => notif.type !== NotificationType.FriendRequest);
							return [...copyNotifs, ...readNotifs];
						});
					}
				});
		}
	};

	const handleAcceptFriendRequest = (notif: Notification) => {
		UserService.acceptFriendRequest(notif.sender.name)
			.then(data => {
				if (!data.isError) {
					setNotifs(notifs => notifs.filter(n => n.id !== notif.id));
				}
			});
	};

	const handleRefuseFriendRequest = (notif: Notification) => {
		NotificationService.deleteNotification(notif.id)
			.then(data => {
				if (!data.isError) {
					setNotifs(notifs => notifs.filter(n => n.id !== notif.id));
				}
			});
	};

	const getMenuItems = () => {
		const items: JSX.Element[] = [];
		let i = 0;

		for (const notif of friendNotifs) {
			if (i > 0) {
				items.push(<Divider key={`friend-notif-divider-${notif.id}`} />);
			}

			items.push(<MenuItem
				key={`friend-notif-${notif.id}`}

			>
				<NotifItem
					text={`${notif.sender.name} vous demande en ami`}
					mainAction={{
						label: "Accepter",
						onClick: () => handleAcceptFriendRequest(notif)
					}}
					secondaryAction={{
						label: "Refuser",
						onClick: () => handleRefuseFriendRequest(notif)
					}}
					avatar={notif.sender.profilePicture}
					onAvatarClick={() => navigate(`/user/${notif.sender.name}`)}
				/>
			</MenuItem>);

			i++;
		}

		return items;
	};

	return (
		<IconMenu
			id="friend-notif-menu"
			icon={
				<Badge
					className={classes.badge}
					badgeContent={friendNotifs.filter(n => !n.read).length}
					color="error"
				>
					<SupervisedUserCircleIcon
						color="secondary"
						sx={{
							width: iconSize,
							height: iconSize,
						}}
					/>
				</Badge>
			}
			onIconClick={handleMarkAllAsRead}
		>
			{ getMenuItems() }
		</IconMenu>
	);
}

export default FriendNotifMenu;