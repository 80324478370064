import React from "react";
import { Grid, Paper, Theme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import UserSearchBar from "./UserSearchBar";
import ProfileMenu from "./ProfileMenu";
import FriendNotifMenu from "./FriendNotifMenu";
import MessageNotifMenu from "./MessageNotifMenu";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 10,
	},
	title: {
		textDecoration: "none",
		color: theme.palette.text.primary,
	}
}));

const ICON_SIZE = 40;

function Header () {
	const classes = useStyles();

	return (
		<Paper elevation={3} className={classes.root}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Link to="/" className={classes.title}>
						<Typography variant="h5">GAMESNET</Typography>
					</Link>
				</Grid>
				<Grid item>
					<UserSearchBar />
				</Grid>
				<Grid item>
					<Grid container justifyContent="flex-end" alignItems="center">
						<Grid item>
							<FriendNotifMenu iconSize={ICON_SIZE} />
						</Grid>
						<Grid item>
							<MessageNotifMenu iconSize={ICON_SIZE} />
						</Grid>
						<Grid item>
							<ProfileMenu iconSize={ICON_SIZE} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default Header;