import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Container, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import Page from "components/Page";
import { useNavigate } from "react-router-dom";
import { GameService } from "services/appApi/GameService";
import { GameData } from "models/GameData";

function GameCatalogPage () {
	const [games, setGames] = useState<GameData[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		GameService.getGamesList()
			.then(data => {
				if (!data.isError) {
					setGames(data.value);
				}
			});
	}, []);

	const handleGotoGame = (game: string) => {
		navigate(`/game/${game}`);
	};

	return (
		<Page>
			<Container maxWidth="lg">
				<Typography variant="h4">Catalogue de jeux</Typography>
				<Grid container rowSpacing={2} mt={2}>
					{
						games.map(game => (
							<Grid item key={game.name} xs={12} sm={6} md={4}>
								<Paper
									key={game.name}
									elevation={3}
									sx={{
										width: 300,
										height: "100%",
									}}
								>
									<Stack spacing={2} alignItems="center" height="100%">
										<Avatar
											src={game.icon}
											sx={{
												width: 70,
												height: 70,
											}}
										/>
										<Typography variant="h5" fontWeight="bold">{game.name}</Typography>
										<Divider color="secondary" sx={{width: "100%"}} />
										<Typography variant="body1" flex={1}>{game.description}</Typography>
										<Box mt={2} width="100%">
											<Button
												variant="contained"
												fullWidth
												onClick={() => handleGotoGame(game.name)}
											>
                                                Jouer
											</Button>
										</Box>
									</Stack>
								</Paper>
							</Grid>
						))
					}
				</Grid>
			</Container>
		</Page>
	);
}

export default GameCatalogPage;