import React, { useEffect } from "react";
import { Box } from "@mui/material";

interface IProps {
	children: React.ReactNode;
	title?: string;
	fullHeight?: boolean;
	centerY?: boolean;
}

function Page ({ children, title, fullHeight, centerY }: IProps) {

	useEffect(() => {
		document.title = "Gamesnet" + (title ? ` - ${title}` : "");
	}, [title]);

	return (
		<Box
			pt={10}
			height={fullHeight || centerY ? "100%" : "auto"}
			display="flex"
			alignItems={centerY ? "center" : "initial"}
		>
			{children}
		</Box>
	);
}

export default Page;