import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PostItem from "./PostItem";
import PostPlaceholder from "./PostPlaceholder";
import Alert, { AlertOptions } from "components/Alert";
import { useUser } from "components/providers/AuthProvider";
import { Post } from "models/Post";
import { PostService } from "services/appApi/PostService";

interface IProps {
	posts: Post[];
	hasMore: boolean;
	isLoading: boolean;
	emptyPost: Post
	onSetPost: React.Dispatch<React.SetStateAction<Post[]>>;
	onLoadPosts: (page: number) => void;
}

function PostList ({ posts, hasMore, isLoading, emptyPost, onSetPost, onLoadPosts }: IProps) {
	const [page, setPage] = useState(2); // Start from 2 because we already fetch 1st page
	const [alertOptions, setAlertOptions] = useState<AlertOptions>();

	const user = useUser();

	const handleMorePosts = () => {
		onLoadPosts(page);
		setPage(page => page + 1);
	};

	const handleToggleLike = (postId: number, callback: (success: boolean) => void) => {
		PostService.toggleLike(postId)
			.then(data => {
				if (!data.isError) {
					const inx = posts.findIndex(p => p.id === postId);
					if (inx !== -1) {
						const post = posts[inx];
						const editedPost: Post = {
							...post,
							likeCount: !post.isLiked ? post.likeCount + 1 : post.likeCount - 1,
							isLiked: !post.isLiked,
						};
						const newPosts = [...posts];
						newPosts[inx] = editedPost;
						onSetPost(newPosts);
						callback(true);
						return;
					}
				}
				callback(false);
			})
			.catch(() => {
				setAlertOptions({
					text: "Une erreur est survenue",
					type: "error",
					open: true
				});
			});
	};

	const handleNewComment = (postId: number, comment: string, callback: (success: boolean) => void) => {
		PostService.newComment(postId, comment)
			.then(data => {
				if (!data.isError) {
					const inx = posts.findIndex(p => p.id === postId);
					if (inx !== -1) {
						const post = posts[inx];
						const editedPost: Post = {
							...post,
							comments: [...post.comments, data.value],
						};
						const newPosts = [...posts];
						newPosts[inx] = editedPost;
						onSetPost(newPosts);
						callback(true);
						return;
					}
				}
				callback(false);
			})
			.catch(() => {
				setAlertOptions({
					text: "Erreur lors de l'envoi du commentaire",
					type: "error",
					open: true,
				});
			});
	};

	const handleDeleteComment = (postId: number, commentId: number) => {
		PostService.deleteComment(commentId)
			.then(data => {
				if (data.isError === false) {
					const postInx = posts.findIndex(p => p.id === postId);
					if (postInx !== -1) {
						const post = posts[postInx];
						const commentInx = post.comments.findIndex(c => c.id === commentId);
						if (commentInx !== -1) {
							const newComments = [...post.comments];
							newComments.splice(commentInx, 1);
							const editedPost: Post = {
								...post,
								comments: newComments,
							};
							const newPosts = [...posts];
							newPosts[postInx] = editedPost;
							onSetPost(newPosts);

							setAlertOptions({
								text: "Commentaire supprimé avec succès",
								type: "success",
								open: true,
							});
						}
					}
				}
			})
			.catch(err => {
				setAlertOptions({
					text: err,
					type: "error",
					open: true,
				});
			});
	};

	const handleDeletePost = (postId: number) => {
		PostService.deletePost(postId)
			.then(data => {
				if (!data.isError) {
					const inx = posts.findIndex(p => p.id === postId);
					if (inx !== -1) {
						const newPosts = [...posts];
						newPosts.splice(inx, 1);
						onSetPost(newPosts);

						setAlertOptions({
							text: "Votre publication a été supprimée",
							type: "success",
							open: true
						});
					}
				}
			})
			.catch(() => {
				setAlertOptions({
					text: "Erreur lors de la suppression de votre publication",
					type: "error",
					open: true
				});
			});
	};

	const handleCloseAlert = () => {
		setAlertOptions(options => ({
			text: options?.text || "",
			type: options?.type || "info",
			open: false
		}));
	};

	return (
		<React.Fragment>
			<InfiniteScroll
				dataLength={posts.length}
				next={handleMorePosts}
				hasMore={hasMore}
				loader={<PostPlaceholder emptyPost={emptyPost} showEmptyPost={!isLoading && posts.length === 0}/>}
			>
				{
					!isLoading && posts.length > 0 && posts.map(post => (
						<PostItem
							post={post}
							localUserName={user?.name || ""}
							onToggleLike={handleToggleLike}
							onNewComment={handleNewComment}
							onDeleteComment={handleDeleteComment}
							onDeletePost={handleDeletePost}
							key={post.id}
						/>
					))
				}
			</InfiniteScroll>
			<Alert
				options={alertOptions}
				onClose={handleCloseAlert}
			/>
		</React.Fragment>
	);
}

export default PostList;