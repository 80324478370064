import React, { useState, useEffect } from "react";
import { Typography, TextField, Paper, Grid, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import { AuthService } from "services/appApi/AuthService";

interface IProps {
	onReset: VoidFunction;
}

function ResetPswForm ({ onReset }: IProps) {
	const [psw, setPsw] = useState("");
	const [pswConfirm, setPswConfirm] = useState("");
	const [userId, setUserId] = useState("");
	const [resetToken, setResetToken] = useState("");

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const location = useLocation();

	useEffect(() => {
		if (location.search) {
			const params = new URLSearchParams(location.search);
			const token = params.get("resetToken");
			const idOfUser = params.get("userId");

			setResetToken(token || "");
			setUserId(idOfUser || "");
		}
	}, []);

	const handlePswChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPsw(e.target.value);
	};

	const handlePswConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPswConfirm(e.target.value);
	};

	const handleReset = () => {
		setLoading(true);
		AuthService.resetPassword(userId, resetToken, psw, pswConfirm)
			.then(data => {
				setLoading(false);
				if (data.isError) {
					setErrors(data.message.split("\n"));
					return;
				}

				setErrors([]);
				setPsw("");
				setPswConfirm("");

				onReset();
			})
			.catch(err => {
				setLoading(false);

				if (err) {
					setErrors(err.split("\n"));
				} else {
					setErrors(["Une erreur est survenue !"]);
				}
			});
	};

	return (
		<Paper elevation={3}>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Typography textAlign="center" variant="h5">Changer de mot de passe</Typography>
					<Divider color="primary" />
				</Grid>
				{
					errors && errors.length > 0 && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: "red" }}>
								<ul>
									{
										errors.map((error, inx) => (
											<li key={inx}>{error}</li>
										))
									}
								</ul>
							</Paper>
						</Grid>
					)
				}
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						autoFocus
						type="password"
						name="psw"
						label="Nouveau mot de passe"
						value={psw}
						onChange={handlePswChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						type="password"
						name="pswConfirm"
						label="Confirmez le nouveau mot de passe"
						value={pswConfirm}
						onChange={handlePswConfirmChange}
					/>
				</Grid>
				<Grid item>
					<LoadingButton
						variant="contained"
						color="primary"
						fullWidth
						disabled={!psw || !pswConfirm}
						loading={loading}
						onClick={handleReset}
					>
						Changer mon mot de passe
					</LoadingButton>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default ResetPswForm;