import React from "react";
import { Avatar, Button, Stack, Typography } from "@mui/material";

interface IAction {
	label: string;
	onClick: () => void;
}

interface IProps {
	text: string;
	mainAction?: IAction;
	secondaryAction?: IAction;
	avatar?: string;
	onAvatarClick?: () => void;
}

function NotifItem ({ text, mainAction, secondaryAction, avatar, onAvatarClick }: IProps) {
	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={2}
		>
			<Avatar src={avatar} onClick={onAvatarClick} />
			<Stack spacing={1}>
				<Typography variant="body1">{text}</Typography>
				<Stack direction="row" justifyContent="space-between" spacing={1}>
					{
						mainAction && (
							<Button variant="contained" color="primary" onClick={mainAction.onClick}>
								{mainAction.label}
							</Button>
						)
					}
					{
						secondaryAction && (
							<Button variant="contained" color="secondary" onClick={secondaryAction.onClick}>
								{secondaryAction.label}
							</Button>
						)
					}
				</Stack>
			</Stack>
		</Stack>
	);
}

export default NotifItem;