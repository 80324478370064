import React, { useState, useEffect } from "react";
import { Avatar, Button, Container, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "components/providers/AuthProvider";
import { User } from "models/User";
import { UserService } from "services/appApi/UserService";
import { FriendStatus } from "models/User";
import { NotificationService } from "services/appApi/NotificationService";
import { LoadingButton } from "@mui/lab";
import PostList from "components/Post/PostList";
import { Post } from "models/Post";
import { PostService } from "services/appApi/PostService";
import Page from "components/Page";
import NoPostImg from "assets/images/noPost.png";

const useStyles = makeStyles((theme: Theme) => ({
	banner: {
		backgroundColor: theme.palette.primary.main,
		height: "30vh",
		backgroundSize: "cover",
		backgroundPosition: "center",
	},
	userAvatarContainer: {
		position: "absolute",
		top: -25,
	},
	userAvatar: {
		width: "10vw",
		height: "10vw",
		minWidth: 70,
		minHeight: 70,
		maxWidth: 120,
		maxHeight: 120,
	}
}));

function ProfilePage () {
	const [user, setUser] = useState<User>();
	const [userPosts, setUserPosts] = useState<Post[]>([]);
	const [isOwnPage, setIsOwnPage] = useState(false);
	const [isLoadingFriendBtn, setIsLoadingFriendBtn] = useState(false);
	const [isPostLoading, setIsPostLoading] = useState(false);
	const [hasMorePosts, setHasMorePosts] = useState(true);

	const classes = useStyles();
	const loggedUser = useUser();
	const params = useParams();
	const navigate = useNavigate();

	const { userName } = params;

	useEffect(() => {
		const userToSearch = (userName || loggedUser?.name) ?? "";

		setIsOwnPage(userToSearch === loggedUser?.name);
		setUserPosts([]);

		UserService.getUser(userToSearch)
			.then(data => {
				if (!data.isError) {
					setUser(data.value);
					handleGetUserPosts(1);
				}
			});
	}, [userName]);

	const handleGetUserPosts = (page: number) => {
		if (!isPostLoading) {
			setIsPostLoading(true);
			PostService.getUserPosts(userName || "", page, 10)
				.then(data => {
					setIsPostLoading(false);
					if (!data.isError) {
						setUserPosts(userPosts => [...userPosts, ...data.value.posts]);
						setHasMorePosts(data.value.totalPages !== page);
					}
				})
				.catch(() => setIsPostLoading(false));
		}
	};

	const handleGoSettings = () => {
		navigate("/settings");
	};

	const handleAddFriend = () => {
		if (user) {
			setIsLoadingFriendBtn(true);
			NotificationService.sendFriendRequest(user.name)
				.then(data => {
					if (!data.isError) {
						setUser({
							...user,
							friendStatus: FriendStatus.Requested
						});
						setIsLoadingFriendBtn(false);
					}
				})
				.catch(() => setIsLoadingFriendBtn(false));
		}
	};

	const handleAcceptFriend = () => {
		if (user) {
			setIsLoadingFriendBtn(true);
			UserService.acceptFriendRequest(user.name)
				.then(data => {
					if (!data.isError) {
						setUser({
							...user,
							friendStatus: FriendStatus.Friend
						});
						setIsLoadingFriendBtn(false);
					}
				})
				.catch(() => setIsLoadingFriendBtn(false));
		}
	};

	const handleRemoveFriend = () => {
		if (user) {
			setIsLoadingFriendBtn(true);
			UserService.removeFriend(user.name)
				.then(data => {
					if (!data.isError) {
						setUser({
							...user,
							friendStatus: FriendStatus.NoFriend
						});
						setIsLoadingFriendBtn(false);
					}
				})
				.catch(() => setIsLoadingFriendBtn(false));
		}
	};

	const getFriendRequestButton = () => {
		if (isOwnPage) {
			return null;
		}

		switch (user?.friendStatus) {
			case FriendStatus.NoFriend:
				return (
					<LoadingButton
						variant="contained"
						loading={isLoadingFriendBtn}
						onClick={handleAddFriend}
					>
						Ajouter en ami
					</LoadingButton>
				);

			case FriendStatus.Requested:
				return (
					<Button variant="contained" disabled>Demande envoyée</Button>
				);

			case FriendStatus.Pending:
				return (
					<LoadingButton
						variant="contained"
						loading={isLoadingFriendBtn}
						onClick={handleAcceptFriend}
					>
						Accepter la demande
					</LoadingButton>
				);

			case FriendStatus.Friend:
				return (
					<LoadingButton
						variant="outlined"
						color="secondary"
						loading={isLoadingFriendBtn}
						onClick={handleRemoveFriend}
					>
						Retirer des amis
					</LoadingButton>
				);

			default:
				break;
		}
	};

	const getUserAvatar = () => {
		return (
			<Grid item xs={6} sm={2} style={{ position: "relative" }}>
				<Grid className={classes.userAvatarContainer} container direction="column" alignItems="center" spacing={1}>
					<Grid item>
						<Avatar className={classes.userAvatar} src={user?.profilePicture} />
					</Grid>
					<Grid item>
						{ getFriendRequestButton() }
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const emptyPost: Post = {
		id: -1,
		authorName: user?.name ?? "Gamesnet",
		authorProfilePicture: user?.profilePicture,
		textContent: "Cet utilisateur n'a aucune publication pour le moment.",
		mediaUrl: NoPostImg,
		mediaType: "image",
		isLiked: false,
		likeCount: 0,
		comments: [],
	};

	return (
		<Page title={user?.name}>
			<Container maxWidth="lg">
				<Grid
					className={classes.banner}
					style={{ backgroundImage: `url(${user?.banner})` }}
					container
					justifyContent="flex-end"
					alignItems="flex-end"
				>
					{
						isOwnPage && (
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleGoSettings}
							>
							Editer mon profil
							</Button>
						)
					}
				</Grid>
				<Grid container>
					{getUserAvatar()}
					<Grid item xs={6} sm={10}>
						<Typography variant="h6">{user?.name}</Typography>
						<Typography variant="body1">{user?.description}</Typography>
					</Grid>
				</Grid>
				<Container maxWidth="sm" sx={{ my: 5 }}>
					<PostList
						hasMore={hasMorePosts}
						posts={userPosts}
						isLoading={isPostLoading}
						emptyPost={emptyPost}
						onSetPost={setUserPosts}
						onLoadPosts={handleGetUserPosts}
					/>
				</Container>
			</Container>
		</Page>
	);
}

export default ProfilePage;