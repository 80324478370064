import axios from "axios";
import { AxiosConfigurator } from "./AxiosConfigurator";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { NotifListResponse } from "models/requestsResponses/NotifResponse";

const config = AxiosConfigurator.config;

/** 
 * Defines notification service 
 */
export class NotificationService {
	/**
	 * Gets all notifications for a user
	 * 
	 * @returns {Promise<NotifListResponse>} The notifications list
	 */
	static async getNotifications () {
		const response = await axios.post("/notif/getnotifs", undefined, config);
		return response.data as NotifListResponse;
	}

	/** 
	 * Marks notifications as read
	 * 
	 * @param {number[]} notifIds The notification ids list to mark as read
	 * @returns {Promise<RequestResponse<true>>} The request response
	 */
	static async markedAsRead (notifIds: number[]) {
		const response = await axios.post("/notif/markasread", { ids: notifIds }, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Send friend request
	 * 
	 * @param {string} userName The user's name to send friend request
	 * @returns {Promise<RequestResponse<true>>} The request response
	 */
	static async sendFriendRequest (userName: string) {
		const response = await axios.post("/notif/createfriendrequest", { receiverName: userName }, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Delete a notification
	 * 
	 * @param {number} notifId The notification id to delete
	 */
	static async deleteNotification (notifId: number) {
		const response = await axios.post("/notif/delete", { id: notifId }, config);
		return response.data as RequestResponse<true>;
	}
}