import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GameService } from "services/appApi/GameService";
import { Game, GameConfiguration } from "models/game/Game";
import { ConfigParam, defaultGameConfig } from "models/game/DefaultConfig";
import Alert, { useAlertOption } from "components/Alert";
import ConfigForm from "./ConfigForm";

interface IProps {
	gameName: string;
	isOpen: boolean;
	onCreateGame: (game: Game) => void;
	onClose: () => void;
}

function NewGameDialog ({ gameName, isOpen, onCreateGame, onClose }: IProps) {
	const [config, setConfig] = useState<Partial<GameConfiguration>>();
	const [defaultConfig, setDefaultConfig] = useState<ConfigParam[]>([]);

	const { alertOptions, openAlert, closeAlert } = useAlertOption();

	useEffect(() => {
		if (gameName) {
			const defaultConf = defaultGameConfig[gameName];
			if (defaultConf) {
				setDefaultConfig(defaultConf);

				let newConfig: Partial<GameConfiguration> = {};
				for (const param of defaultConf) {
					newConfig = {
						...newConfig,
						[param.name]: param.value ?? param.defaultValue
					};
				}
				setConfig(newConfig);
			} else {
				setDefaultConfig([]);
			}
		}
	}, [gameName]);

	const setConfigProp = (prop: string, value: string | number | boolean) => {
		const configParam = defaultConfig.find(p => p.name === prop);
		if (configParam?.value) {
			return;
		}

		setConfig(config => ({
			...config,
			[prop]: value
		}));
	};

	const handleCreateGame = () => {
		GameService.createGame(gameName, config as GameConfiguration)
			.then(data => {
				if (!data.isError) {
					onCreateGame(data.value);
					onClose();
				}
			})
			.catch(err => {
				let errorMessage = "Une erreur est survenue.";
				if (typeof err === "string") {
					errorMessage = err;
				}

				openAlert(errorMessage, "error");
			});
	};

	return (
		<Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
			<Alert options={alertOptions} onClose={closeAlert} />
			<DialogTitle>
        Nouvelle partie
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<ConfigForm
					config={config}
					onConfigChange={setConfigProp}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleCreateGame}>Créer</Button>
			</DialogActions>
		</Dialog>
	);
}

export default NewGameDialog;