import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { SocketMessages } from "models/socket/SocketMessages";

const ENDPOINT = process.env.REACT_APP_SERVER_URL as string;

/**
 * Define socket service
 */
export class SocketService {
	private static socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;
	private static waitingListenersForConnect: {
		message: SocketMessages;
		action: (...args: unknown[]) => void;
	}[] = [];

	/**
	 * Connect to the socket server
	 */
	static connect () {
		let wasNull = false;
		if (!SocketService.socket) {
			wasNull = true;
			SocketService.socket = io(ENDPOINT, {
				transports: ["websocket"],
			});
		}

		if (wasNull || SocketService.socket?.disconnected) {

			SocketService.socket.connect();

			if (SocketService.socket.listeners(SocketMessages.CONNECT)?.length === 0) {
				SocketService.socket.on(SocketMessages.CONNECT, () => {
					console.log("Socket connected !");

					SocketService.waitingListenersForConnect.forEach((waitingListener) => {
						SocketService.on(waitingListener.message, waitingListener.action);
					});
				});
			}
		}
	}

	/**
	 * Disconnect from the server
	 */
	static disconnect () {
		if (SocketService.socket) {
			SocketService.socket.disconnect();
		}
	}

	/**
	 * Subscribe to a event message
	 * 
	 * @param {SocketMessages} message The message event
	 * @param {(args: any[]) => void} action The action to execute when the message is received
	 */
	static on (
		message: SocketMessages,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action: (...args: any[]) => void
	) {
		if (SocketService.socket?.connected) {
			SocketService.socket.on(message, action);
		} else {
			SocketService.waitingListenersForConnect.push({
				message,
				action
			});
		}
	}

	/**
	 * Emit an event message
	 * 
	 * @param {SocketMessages} message The event message
	 * @param {unknown} data The data to send
	 */
	static emit (message: SocketMessages, ...data: unknown[]) {
		if (SocketService.socket) {
			SocketService.socket.emit(message, ...data);
		}
	}

	/**
	 * Unsubscribe from an event message
	 * 
	 * @param {SocketMessages} message The event message
	 * @param  {(args: any[]) => void} action The ation function to remove
	 */
	static off (
		message: SocketMessages,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action: (...args: any[]) => void
	) {
		if (SocketService.socket) {
			SocketService.socket.off(message, action);
		}
	}

	/**
	 * Unsubscribe from all events of a message
	 * 
	 * @param {SocketMessages} message The event message to unsubscribe from
	 */
	static removeAllListeners (message: SocketMessages) {
		if (SocketService.socket) {
			SocketService.socket.removeAllListeners(message);
		}
	}

	/**
	 * Subscribe to ERROR_MESSAGE event message
	 * 
	 * @param {(error: string) => void} action The action to execute when the message is received
	 * @returns {() => void} The function to unsubscribe from the message
	 */
	static onError (action: (error: string) => void) {
		SocketService.on(SocketMessages.ERROR_MESSAGE, action);

		return () => SocketService.off(SocketMessages.ERROR_MESSAGE, action);
	}
}