import axios from "axios";
import { PostVisibility } from "models/Post";
import { NewPostCommentResponse, PostListResponse } from "models/requestsResponses/PostResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";
import { AxiosConfigurator } from "./AxiosConfigurator";

const config = AxiosConfigurator.config;

/** 
 * Defines post service 
 */
export class PostService {
	/**
	 * Creates a new post
	 * 
	 * @param {string} description - The description of the post
	 * @param {PostVisibility} visibility - The visibility of the post
	 * @param {File} media - The media (photo or video) attached to the post
	 */
	static async createPost (description: string, visibility: PostVisibility, media?: File) {
		const formData = new FormData();
		formData.append("textContent", description);
		formData.append("visibility", visibility);
		if (media) {
			formData.append("media", media);
		}
		const response = await axios.post("/post/create", formData, AxiosConfigurator.formDataConfig);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Delete a post
	 * 
	 * @param {number} postId The id of the post
	 * @returns {Promise<RequestResponse<true>>} The request response 
	 */
	static async deletePost (postId: number) {
		const response = await axios.post("/post/delete", {
			id: postId
		}, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Gets the user list of posts
	 * 
	 * @param {string} userName - The user name of the user whose posts are to be fetched
	 * @param {number} page - The page number of the posts to be fetched
	 * @param {number} itemPerPage - The number of posts to be fetched per page
	 * @returns {Promise<PostListResponse>} - The list of posts
	 */
	static async getUserPosts (userName: string, page: number, itemPerPage: number) {
		const response = await axios.post("/post/getuserposts", {
			userName,
			page,
			itemPerPage
		}, config);
		return response.data as PostListResponse;
	}

	/** 
	 * Gets feeds posts 
	 * 
	 * @param {number} page The page number of the posts to be fetched
	 * @param {number} itemPerPage The number of posts to be fetched per page
	 * @returns {Promise<PostListResponse>} The list of posts
	 */
	static async getFeeds (page: number, itemPerPage: number) {
		const response = await axios.post("/post/feeds", {
			page,
			itemPerPage
		}, config);
		return response.data as PostListResponse;
	}

	/**
	 * Toggle post like
	 * 
	 * @param {number} postId The id of the post
	 */
	static async toggleLike (postId: number) {
		const response = await axios.post("/post/togglelike", {
			id: postId
		}, config);
		return response.data as RequestResponse<boolean>;
	}

	/**
	 * Creates a new comment on a post
	 * 
	 * @param {number} postId The id of the post
	 * @param {string} comment The comment
	 * @returns {Promise<NewPostCommentResponse>} The new comment
	 */
	static async newComment (postId: number, comment: string) {
		const response = await axios.post("/post/newcomment", {
			postId,
			comment
		}, config);
		return response.data as NewPostCommentResponse;
	}

	/** 
	 * Deletes a comment on a post
	 * 
	 * @param {number} commentId The id of the comment
	 * @returns {Promise<RequestResponse<true>>} The request response
	 */
	static async deleteComment (commentId: number) {
		const response = await axios.post("/post/deletecomment", { id: commentId }, config);
		return response.data as RequestResponse<true>;
	}
}