export enum PostVisibility {
	PUBLIC = "PUBLIC",
	FRIENDS_ONLY = "FRIENDS_ONLY",
}

export interface Post {
	id: number;
	authorName: string;
	authorProfilePicture?: string;
	textContent: string;
	mediaUrl?: string;
	mediaType?: string;
	comments: Comment[];
	likeCount: number;
	isLiked: boolean;
}

export interface Comment {
	id: number;
	authorName: string;
	authorProfilePicture?: string;
	content: string;
}