import React, { useState, useMemo, useEffect } from "react";
import { Avatar, Box, Grid, IconButton, MenuItem, Paper, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import { useUser } from "components/providers/AuthProvider";
import { PostVisibility } from "models/Post";
import EmojiPicker from "components/EmojiPicker";
import Alert, { AlertOptions } from "components/Alert";
import { PostService } from "services/appApi/PostService";

const useStyles = makeStyles(() => ({
	media: {
		width: "100%",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
	},
	removeMediaBtn: {
		backgroundColor: "#f44336",
		position: "absolute",
		top: 2,
		right: 2,
		zIndex: 10,
	}
}));

function PostForm () {
	const [postVisivility, setPostVisivility] = useState(PostVisibility.FRIENDS_ONLY);
	const [mediaFile, setMediaFile] = useState<File>();

	const [isLoading, setIsLoading] = useState(false);
	const [alertOptions, setAlertOptions] = useState<AlertOptions>();
	const [resetCommentInput, setResetCommentInput] = useState(false);

	const classes = useStyles();
	const user = useUser();

	const inputRef = React.createRef<HTMLInputElement>();
	const mediaInputRef = React.createRef<HTMLInputElement>();

	useEffect(() => {
		if (resetCommentInput && inputRef.current) {
			inputRef.current.value = "";
			setResetCommentInput(false);
		}
	}, [resetCommentInput]);

	const mediaFileElem = useMemo(() => {
		if (!mediaFile) {
			return null;
		}

		const mediaUrl = URL.createObjectURL(mediaFile);
		const isImage = mediaFile.type.includes("image");

		if (isImage) {
			return (
				<img
					className={classes.media}
					src={mediaUrl}
				/>
			);
		} else {
			return (
				<video
					className={classes.media}
					src={mediaUrl}
					controls
					controlsList="nodownload"
				>
					{mediaFile.name}
				</video>
			);
		}

	}, [mediaFile]);

	const handleOpenMediaInput = () => {
		mediaInputRef.current?.click();
	};

	const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}

		setMediaFile(e.target.files.item(0) || undefined);
	};

	const handleRemoveMedia = () => {
		setMediaFile(undefined);
		if (mediaInputRef.current) {
			mediaInputRef.current.value = "";
		}
	};

	const handlePostVisivilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPostVisivility(e.target.value as PostVisibility);
	};

	const handleEmojiClick = (emoji: string) => {
		const myField = inputRef.current;
		if (!myField) {
			return;
		}

		if (myField.selectionStart || myField.selectionStart === 0) {
			const startPos = myField.selectionStart;
			const endPos = myField.selectionEnd || startPos;
			myField.value = myField.value.substring(0, startPos)
        + emoji
        + myField.value.substring(endPos, myField.value.length);
		} else {
			myField.value += emoji;
		}

		myField.focus();
	};

	const handleCloseAlert = () => {
		setAlertOptions({
			type: alertOptions?.type || "info",
			text: alertOptions?.text || "",
			open: false
		});
	};

	const handleSubmitPost = () => {
		if (!inputRef.current?.value) {
			setAlertOptions({
				text: "Saisissez le contenu de votre publication",
				type: "error",
				open: true
			});
			return;
		}

		setIsLoading(true);
		PostService.createPost(inputRef.current.value, postVisivility, mediaFile)
			.then(data => {
				setIsLoading(false);
				if (!data.isError) {
					if (inputRef.current) {
						inputRef.current.value = "";
					}

					if (mediaInputRef.current) {
						mediaInputRef.current.value = "";
					}

					setMediaFile(undefined);
					setResetCommentInput(true);
					setAlertOptions({
						text: "Votre publication a été publiée avec succès",
						type: "success",
						open: true
					});
				} else {
					setAlertOptions({
						text: data.message || "Une erreur est survenue lors de la publication",
						type: "error",
						open: true
					});
				}
			})
			.catch(() => {
				setIsLoading(false);
				setAlertOptions({
					text: "Une erreur est survenue lors de la publication",
					type: "error",
					open: true
				});
			});
	};

	return (
		<Paper elevation={3}>
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={2}>
					<Avatar
						sx={{
							width: 64,
							height: 64,
						}}
						src={user?.profilePicture}
					/>
				</Grid>
				<Grid item xs={10}>
					<TextField
						inputRef={inputRef}
						name="description"
						label="Quoi de neuf ?"
						variant="outlined"
						color="secondary"
						fullWidth
						multiline
						minRows={2}
						maxRows={5}
						InputProps={{
							endAdornment: (
								<Box
									sx={{
										width: 30
									}}
								>
									<EmojiPicker onEmojiClick={handleEmojiClick} />
									<IconButton
										color="secondary"
										size="small"
										onClick={handleOpenMediaInput}
									>
										<AddAPhotoIcon />
									</IconButton>
								</Box>
							)
						}}
					/>
					<input
						ref={mediaInputRef}
						type="file"
						style={{ display: "none" }}
						accept="image/x-png,image/gif,image/jpeg, video/mp4,video/x-m4v,video/quicktime,video/x-msvideo"
						onChange={handleMediaChange}
					/>
				</Grid>
			</Grid>
			{
				mediaFile && (
					<Box mt={2} sx={{ position: "relative" }}>
						<IconButton
							className={classes.removeMediaBtn}
							color="secondary"
							size="small"
							onClick={handleRemoveMedia}
						>
							<RemoveIcon />
						</IconButton>
						{mediaFileElem}
					</Box>
				)
			}
			<Box sx={{
				mt: 2,
				display: "flex",
				justifyContent: "space-between"
			}}>
				<TextField
					name="visibility"
					select
					label="Visibilité"
					variant="outlined"
					color="secondary"
					size="small"
					value={postVisivility}
					sx={{ minWidth: 200 }}
					onChange={handlePostVisivilityChange}
				>
					<MenuItem value={PostVisibility.PUBLIC}>Public</MenuItem>
					<MenuItem value={PostVisibility.FRIENDS_ONLY}>Amis uniquement</MenuItem>
				</TextField>
				<LoadingButton
					variant="contained"
					size="small"
					loading={isLoading}
					onClick={handleSubmitPost}
				>
          Publier
				</LoadingButton>
			</Box>
			<Alert
				options={alertOptions}
				onClose={handleCloseAlert}
			/>
		</Paper>
	);
}

export default PostForm;