import axios from "axios";
import { AxiosConfigurator } from "./AxiosConfigurator";
import { UsersListResponse, UserResponse } from "models/requestsResponses/UserResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";

const config = AxiosConfigurator.config;

/** 
 * Defines user service 
 */
export class UserService {
	/**
	 * Get a user by name
	 * 
	 * @param {string} userName The user's name
	 * @returns {Promise<UserResponse>} The user's data
	 */
	static async getUser (userName: string) {
		const response = await axios.post("/user", { userName }, config);
		return response.data as UserResponse;
	}

	/**
	 * Search users by name substring
	 * 
	 * @param {string} userName The substring to search
	 * @returns {Promise<UsersListResponse>} The users list
	 */
	static async searchUsers (userName: string) {
		const response = await axios.post("/user/search", { userName }, config);
		return response.data as UsersListResponse;
	}

	/**
	 * Edit user's profile
	 * 
	 * @param {string} description The user's description
	 * @param {File} profilePicture The user's profile picture
	 * @param {File} banner The user's banner
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async editUser (description: string, profilePicture?: File | null, banner?: File | null) {
		const formData = new FormData();
		formData.append("description", description);

		if (profilePicture) {
			formData.append("profilePicture", profilePicture);
		}

		if (banner) {
			formData.append("banner", banner);
		}

		const response = await axios.post("/user/edit", formData, AxiosConfigurator.formDataConfig);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Accept friend request
	 * 
	 * @param {string} friendName The friend's name
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async acceptFriendRequest (friendName: string) {
		const response = await axios.post("/user/accept-friend-request", { userName: friendName }, config);
		return response.data as RequestResponse<true>;
	}

	/** 
	 * Remove friend from friends list
	 * 
	 * @param {string} friendName The friend's name 
	 * @returns {Promise<RequestResponse<true>>} The default response
	 */
	static async removeFriend (friendName: string) {
		const response = await axios.post("/user/remove-friend", { userName: friendName }, config);
		return response.data as RequestResponse<true>;
	}

	/**
	 * Get user's friends list
	 * 
	 * @returns {Promise<UsersListResponse>} The logged user's friends list
	 */
	static async getMyFriends () {
		const response = await axios.get("/user/my-friends", config);
		return response.data as UsersListResponse;
	}
}