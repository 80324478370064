import React, { useState, useEffect } from "react";
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useDisclosure } from "hooks/customHooks";
import { useNavigate } from "react-router-dom";
import { GameService } from "services/appApi/GameService";
import { GameSocketService } from "services/socket/Game/GameSocketService";
import { GameInvitation } from "models/game/GameInvitation";
import { GameData } from "models/GameData";

function InvitationReceivedDialog () {
	const [invite, setInvite] = useState<GameInvitation>();
	const [gameInfo, setGameInfo] = useState<GameData>();
	const [isOpen, open, close] = useDisclosure();

	const navigate = useNavigate();

	useEffect(() => {
		const offOnIntatedToGame = GameSocketService.onInvitedToGame(onInvitedToGame);

		return () => {
			offOnIntatedToGame();
		};
	}, []);

	const onInvitedToGame = (invitation: GameInvitation) => {
		GameService.getGameByName(invitation.gameName)
			.then(data => {
				if (!data.isError) {
					setGameInfo(data.value);
				}
			});

		setInvite(invitation);
		open();
	};

	const handlePlay = () => {
		if (invite) {
			navigate(`/game/${invite.gameName}`, { state: { inviteRoom: invite.roomId } });
			close();
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={close}
		>
			<DialogTitle>
				Invitation à jouer
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Stack direction="row" justifyContent="space-evenly">
						<Avatar src={invite?.sender.profilePicture} />
						<Avatar src={gameInfo?.icon} />
					</Stack>
					<Typography><b>{invite?.sender.name}</b> vous invite à jouer à <b>{gameInfo?.name}</b></Typography>
					<Typography>{"Cliquez sur \"jouer\" pour rejoinre la partie"}</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<LoadingButton variant="contained" onClick={handlePlay}>Jouer</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

export default InvitationReceivedDialog;