import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "components/providers/AuthProvider";
import LoadingPage from "pages/Loading/LoadingPage";

interface IProps {
	children: JSX.Element;
}

function ProtectedRoute ({ children }: IProps) {
	const { user, firstLoginLoading, loginWithCookie } = useAuth();
	const isUserLogged = !!user;
	const location = useLocation();

	useEffect(() => {
		if (!isUserLogged) {
			loginWithCookie();
		}
	}, []);

	if (!user && firstLoginLoading) {
		return <LoadingPage />;
	}

	if (!user) {
		return <Navigate to="/login" state={{ from: location.pathname }} />;
	}

	return children;
}

export default ProtectedRoute;