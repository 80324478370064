import React, { useState } from "react";
import { Avatar, Box, Divider, Grid, IconButton, MenuItem, Paper, Stack, TextField, Theme, Typography, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import PostComment from "./PostComment";
import IconMenu from "components/IconMenu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import SendIcon from "@mui/icons-material/Send";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Post } from "models/Post";

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		textDecoration: "none",
		color: theme.palette.text.primary,
	},
	icon: {
		width: 32,
		height: 32,
		color: theme.palette.text.primary,
	},
	media: {
		maxWidth: "100%",
	}
}));

interface IProps {
	post: Post;
	localUserName: string;
	readonly?: boolean;
	onToggleLike: (postId: number, callback: (success: boolean) => void) => void;
	onNewComment: (postId: number, comment: string, callback: (success: boolean) => void) => void;
	onDeleteComment: (postId: number, commentId: number) => void;
	onDeletePost: (postId: number) => void;
}

function PostItem ({
	post, localUserName, readonly, onToggleLike, onNewComment, onDeleteComment, onDeletePost
}: IProps) {
	const [newCommentTxt, setNewCommentTxt] = useState("");
	const [likeZoomIn, setLikeZoomIn] = useState(true);
	const [showComments, setShowComments] = useState(false);

	const classes = useStyles();

	const handleToggleComments = () => {
		setShowComments(showComments => !showComments);
	};

	const handleNewCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!readonly) {
			setNewCommentTxt(event.target.value);
		}
	};

	const handleSubmitNewComment = () => {
		if (newCommentTxt.length > 0 && !readonly) {
			onNewComment(post.id, newCommentTxt, (success: boolean) => {
				if (success) {
					setNewCommentTxt("");
					setShowComments(true);
				}
			});
		}
	};

	const handleDeleteComment = (commentId: number) => {
		if (!readonly) {
			onDeleteComment(post.id, commentId);
		}
	};

	const handleToggleLike = () => {
		if (!readonly) {
			onToggleLike(post.id, success => {
				if (success) {
					setLikeZoomIn(false);
					setTimeout(() => setLikeZoomIn(true), 200);
				}
			});
		}
	};

	const handleDeletePost = () => {
		if (!readonly) {
			onDeletePost(post.id);
		}
	};

	const getMedia = () => {
		if (post.mediaUrl) {
			if (post.mediaType?.includes("image")) {
				return (
					<img
						src={post.mediaUrl}
						className={classes.media}
					/>
				);
			} else if (post.mediaType?.includes("video")) {
				return (
					<div
						className={classes.media}
						style={{
							position: "relative",
							width: "100%",
							height: 400,
						}}
					>
						<video
							src={post.mediaUrl}
							controls
							controlsList="nodownload"
							width="100%"
							height="100%"
							style={{ position: "absolute" }}
						/>
					</div>
				);
			}
		}
		return null;
	};

	return (
		<Paper sx={{ mb: 2 }}>
			<Grid container direction="column">
				<Grid item>
					<Stack direction="row" justifyContent="space-between">
						<Link to={`/user/${post.authorName}`} className={classes.title}>
							<Grid container alignItems="center" spacing={2}>
								<Grid item>
									<Avatar src={post.authorProfilePicture} />
								</Grid>
								<Grid item>
									<Typography variant="h6">{post.authorName}</Typography>
								</Grid>
							</Grid>
						</Link>
						{post.authorName === localUserName && !readonly && (
							<IconMenu
								id={`post-menu-${post.id}`}
								icon={<MoreHorizIcon color="secondary" />}
							>
								<MenuItem onClick={handleDeletePost}>
									Supprimer
								</MenuItem>
							</IconMenu>
						)}
					</Stack>
				</Grid>
				<Box my={1} sx={{ width: "100%" }}>
					<Divider />
				</Box>
				<Grid item>
					<Typography variant="body1">
						{post.textContent}
					</Typography>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center"
						}}
						mt={2}
					>
						{getMedia()}
					</Box>
				</Grid>
				<Grid item>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Grid container alignItems="center">
								<Grid item>
									<Zoom in={likeZoomIn}>
										<IconButton onClick={handleToggleLike}>
											{
												post.isLiked ?
														<FavoriteIcon className={classes.icon} /> :
														<FavoriteBorderIcon className={classes.icon} />
											}
										</IconButton>
									</Zoom>
								</Grid>
								<Grid item>
									<Typography variant="body1">
										{`${post.likeCount} j'aime(s)`}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<IconButton onClick={handleToggleComments}>
								<CommentIcon className={classes.icon} />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Box my={1} sx={{ width: "100%" }}>
					<Divider />
				</Box>
				{
					showComments && (
						<Grid item>
							<Grid container spacing={3} direction="column">
								{
									post.comments.map(comment => (
										<Grid item key={comment.id}>
											<PostComment
												item={comment}
												isOwner={comment.authorName === localUserName}
												onDeleteComment={handleDeleteComment}
											/>
										</Grid>
									))
								}
							</Grid>
						</Grid>
					)
				}
				<Grid item sx={{ mt: 3 }}>
					<TextField
						name="comment"
						label="Commenter"
						variant="outlined"
						color="secondary"
						fullWidth
						size="small"
						InputProps={{
							endAdornment: (
								<IconButton onClick={handleSubmitNewComment}>
									<SendIcon color="secondary" />
								</IconButton>
							)
						}}
						value={newCommentTxt}
						disabled={readonly}
						onChange={handleNewCommentChange}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default PostItem;