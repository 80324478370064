import React, { useState } from "react";
import { Typography, TextField, Paper, Grid, Divider, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { InfoMessage } from "models/InfoMessage";
import { useAuth } from "components/providers/AuthProvider";

interface IProps {
	infoMessage?: InfoMessage;
	onPswLostClick: () => void;
}

function LoginForm ({ infoMessage, onPswLostClick, }: IProps) {
	const [login, setLogin] = useState("");
	const [psw, setPsw] = useState("");

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const auth = useAuth();

	const onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLogin(e.target.value);
	};

	const onPswChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPsw(e.target.value);
	};

	const handleLogin = () => {
		setLoading(true);
		setErrors([]);

		auth.login(login, psw)
			.then(() => {
				setLoading(false);
			})
			.catch(err => {
				setLoading(false);
				if (err) {
					setErrors(err.split("\n"));
				} else {
					setErrors(["Une erreur est survenue !"]);
				}
			});
	};

	return (
		<Paper elevation={3}>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Typography textAlign="center" variant="h5">Se connecter</Typography>
					<Divider color="primary" />
				</Grid>
				{
					infoMessage && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: infoMessage.color }}>
								<Typography variant="body1">{infoMessage.message}</Typography>
								{
									infoMessage.action && (
										<LoadingButton
											variant="text"
											color="secondary"
											loading={loading}
											onClick={infoMessage.action.onClick}
										>
											{infoMessage.action.label}
										</LoadingButton>
									)
								}
							</Paper>
						</Grid>
					)
				}
				{
					errors && errors.length > 0 && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: "red" }}>
								<ul>
									{
										errors.map((error, inx) => (
											<li key={inx}>{error}</li>
										))
									}
								</ul>
							</Paper>
						</Grid>
					)
				}
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						autoFocus
						type="text"
						name="login"
						label="Votre login"
						value={login}
						onChange={onLoginChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						type="password"
						name="psw"
						label="Votre mot de passe"
						value={psw}
						onChange={onPswChange}
					/>
				</Grid>
				<Grid item>
					<LoadingButton
						variant="contained"
						color="primary"
						fullWidth
						disabled={!login || !psw}
						loading={loading}
						onClick={handleLogin}
					>
						Connexion
					</LoadingButton>
					<Button
						variant="text"
						color="secondary"
						fullWidth
						onClick={onPswLostClick}
					>
						Mot de passe oublié ?
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default LoginForm;