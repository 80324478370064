import { SocketService } from "./SocketService";
import { Notification } from "models/Notification";
import { SocketMessages } from "models/socket/SocketMessages";

/**
 * Defines the notification socket service
 */
export class NotifSocketService {
	/**
	 * Subscribe to NEW_NOTIFICATION event message
	 * 
	 * @param {(notif: Notification) => void} action The action to execute when the message is received
	 * @returns {() => void} Func to unsubscribe from the event function
	 */
	static onNewNotification (action: (notif: Notification) => void) {
		SocketService.on(SocketMessages.NEW_NOTIFICATION, action);

		return () => SocketService.off(SocketMessages.NEW_NOTIFICATION, action);
	}
}