/** 
 * Define a set of utilities that can be used by the application. 
 */
export class Utils {
	/** 
	 * Gets one of property name of the given Type 
	 * 
	 * @param {string} name The name of the property to get
	 * @returns {string} The property name as a string
	 */
	static propertyOf <T> (name: keyof T) {
		return name;
	}
}