export enum SocketMessages {
	// Socket.IO messages
	CONNECT = "connect",

	// General messages
	ERROR_MESSAGE = "errorMessage",

	// Notification messages
	NEW_NOTIFICATION = "newNotification",

	// Chat messages
	NEW_CHAT_MESSAGE = "newChatMessage",
	DELETE_CHAT_MESSAGE = "deleteChatMessage",

	// Game messages
	GAME_UPDATE = "gameUpdate",
	KICKED_FROM_GAME = "kickedFromGame",
	INVITED_TO_GAME = "invitedToGame",
	USER_PLAY = "userPlay",
	GAME_STOP_ERROR = "gameStopError",
	REPLAY_GAME = "replayGame",
}