export enum NotificationType {
	FriendRequest = "FriendRequest",
	NewMessage = "NewMessage",
}

export interface Notification {
	id: number;
	type: NotificationType;
	value: string;
	read: boolean;
	sender: {
		name: string;
		profilePicture?: string;
	}
}