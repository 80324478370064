import React, { useState } from "react";
import { Typography, TextField, Paper, Grid, Divider, Button, Avatar } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AuthService } from "services/appApi/AuthService";

interface IProps {
	onSignup: (email: string) => void;
}

function SignupForm ({ onSignup }: IProps) {
	const [pictureFile, setPictureFile] = useState<File | null>(null);
	const [login, setLogin] = useState("");
	const [description, setDescription] = useState("");
	const [email, setEmail] = useState("");
	const [psw, setPsw] = useState("");
	const [pswConfirm, setPswConfirm] = useState("");

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);

	const [pictureSrc, setPictureSrc] = useState<string | ArrayBuffer | null>(null);

	const pictureInputRef = React.createRef<HTMLInputElement>();

	const handleOpenPictureInput = () => {
		pictureInputRef.current?.click();
	};

	const handlePictureChoosen = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length === 0) {
			return;
		}

		setPictureFile(e.target.files.item(0));

		const fr = new FileReader();
		fr.onload = function () {
			setPictureSrc(this?.result);
		};
		fr.readAsDataURL(e.target.files[0]);
	};

	const onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLogin(e.target.value);
	};

	const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
	};

	const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const onPswChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPsw(e.target.value);
	};

	const onPswConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPswConfirm(e.target.value);
	};

	const handleSignup = () => {
		setLoading(true);
		setErrors([]);

		AuthService.signup(login, email, psw, pswConfirm, description, pictureFile || undefined)
			.then(() => {
				setLoading(false);
				onSignup(email);
			})
			.catch(err => {
				setLoading(false);
				if (err) {
					setErrors(err.split("\n"));
				} else {
					setErrors(["Une erreur est survenue !"]);
				}
			});
	};

	return (
		<Paper elevation={3}>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<Typography textAlign="center" variant="h5">{"S'inscrire"}</Typography>
					<Divider color="primary" />
				</Grid>
				<Grid container item direction="column" alignContent="center" spacing={1}>
					<Grid item alignSelf="center">
						<Avatar src={pictureSrc as string} alt="Photo de profile" sx={{
							width: 100,
							height: 100
						}} />
					</Grid>
					<Grid item>
						<Button variant="outlined" color="secondary" onClick={handleOpenPictureInput}>
							Choisir une photo
						</Button>
					</Grid>
					<input
						ref={pictureInputRef}
						type="file"
						style={{ display: "none" }}
						accept="image/*"
						onChange={handlePictureChoosen}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						autoFocus
						type="text"
						name="login"
						label="Votre pseudo"
						value={login}
						onChange={onLoginChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						multiline
						rows={3}
						type="text"
						name="description"
						label="Décrivez-vous"
						value={description}
						onChange={onDescriptionChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						type="email"
						name="email"
						label="Saisissez votre email"
						value={email}
						onChange={onEmailChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						type="password"
						name="psw"
						label="Votre mot de passe"
						value={psw}
						onChange={onPswChange}
					/>
				</Grid>
				<Grid item>
					<TextField
						color="secondary"
						variant="outlined"
						fullWidth
						type="password"
						name="psw"
						label="Confirmez votre mot de passe"
						value={pswConfirm}
						onChange={onPswConfirmChange}
					/>
				</Grid>
				{
					errors && errors.length > 0 && (
						<Grid item>
							<Paper elevation={1} sx={{ backgroundColor: "red" }}>
								<ul>
									{
										errors.map((error, inx) => (
											<li key={inx}>{error}</li>
										))
									}
								</ul>
							</Paper>
						</Grid>
					)
				}
				<Grid item>
					<LoadingButton
						variant="contained"
						color="primary"
						fullWidth
						loading={loading}
						disabled={!login || !email || !psw || !pswConfirm}
						onClick={handleSignup}
					>
						{"S'inscrire"}
					</LoadingButton>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default SignupForm;