import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useDisclosure } from "hooks/customHooks";
import { InfoMessage } from "models/InfoMessage";

interface PopupAlertContextType {
	open: (info: InfoMessage) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PopupAlertContext = React.createContext<PopupAlertContextType>(null as any);

interface PopupAlertProviderProps {
	children: React.ReactNode;
}

export default function PopupAlertProvider ({ children }: PopupAlertProviderProps) {
	const [info, setInfo] = useState<InfoMessage>();
	const [isOpen, open, close] = useDisclosure();

	const openAlert = (info: InfoMessage) => {
		setInfo(info);
		open();
	};

	return (
		<PopupAlertContext.Provider value={{ open: openAlert }}>
			<PopupAlert open={isOpen} onClose={close} info={info} />
			{children}
		</PopupAlertContext.Provider>
	);
}

interface PopupAlertProps {
	open: boolean;
	info?: InfoMessage
	onClose: () => void;
}

function PopupAlert ({ open, info, onClose }: PopupAlertProps) {
	const handleActionClick = () => {
		if (info?.action) {
			info.action.onClick();
		}
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleActionClick}
		>
			<DialogTitle>{info?.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{info?.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{
					info?.action ? (
						<Button variant="contained" onClick={handleActionClick}>{info.action.label}</Button>
					) : (
						<Button variant="contained" onClick={onClose}>OK</Button>
					)
				}
			</DialogActions>
		</Dialog>
	);
}

export function usePopupAlert () {
	const context = React.useContext(PopupAlertContext);
	if (context === undefined) {
		throw new Error("usePopupAlert must be used within a PopupAlertProvider");
	}
	return context;
}