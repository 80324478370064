import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box, CircularProgress, IconButton, Paper, Stack, TextField, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useUser } from "components/providers/AuthProvider";
import { useChatList } from "components/providers/ChatListProvider";
import { User } from "models/User";
import { useNavigate } from "react-router-dom";
import { ChatGroup, MessageData } from "models/Chat";
import EmojiPicker from "components/EmojiPicker";
import MessageItem from "./MessageItem";
import { ChatSocketService } from "services/socket/ChatSocketService";
import { GetMessagesListResponse } from "models/requestsResponses/ChatResponse";
import { RequestResponse } from "models/requestsResponses/RequestResponse";

interface IStylesProps {
	isMinimized: boolean;
}

const useStyles = makeStyles<undefined, IStylesProps>(() => ({
	container: {
		position: "relative",
		width: 300,
		height: props => props.isMinimized ? 55 : 400,
		padding: "0px !important",
	},
	titleContainer: {
		cursor: "pointer",
	},
	newMsgBadge: {
		position: "absolute",
		top: -10,
		left: 0,
	}
}));

export interface IChatProps {
	groupData: ChatGroup;
	title: string;
	members: User[];
	isGameChat?: boolean;
	events: {
		onGetMessages?:
		(groupId: number | string, page: number, itemPerPage: number) => Promise<GetMessagesListResponse>;
		onSendMessage: (groupId: number | string, message: string) => Promise<RequestResponse<true>>;
		onDeleteMessage?: (messageId: number | string) => Promise<RequestResponse<true>>;
	}
}

function Chat ({ groupData, title, members, isGameChat, events }: IChatProps) {
	const [messages, setMessages] = useState<MessageData[]>([]);
	const [msgPage, setMsgPage] = useState(0);
	const [msgTotalPages, setMsgTotalPages] = useState(1);

	const [resetMsgInput, setResetMsgInput] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMinimized, setIsMinimized] = useState(false);
	const [newMsgBadgeCount, setNewMsgBadgeCount] = useState(0);

	const classes = useStyles({ isMinimized });

	const user = useUser();
	const { closeChat } = useChatList();
	const navigate = useNavigate();
	const theme = useTheme();

	const inputRef = React.createRef<HTMLInputElement>();
	const isMinimizedRef = React.useRef(isMinimized);

	const membersWithoutUser = members.filter(member => member.name !== user?.name);

	useEffect(() => {
		fetchMessages();
		const unsubToNewMsg = ChatSocketService.onNewMessage(onNewMessage);
		const unsubToDeleteMsg = ChatSocketService.onDeleteMessage(onDeleteMessage);

		return () => {
			unsubToNewMsg();
			unsubToDeleteMsg();
		};
	}, []);

	useEffect(() => {
		if (resetMsgInput && inputRef.current) {
			inputRef.current.value = "";
			setResetMsgInput(false);
		}
	}, [resetMsgInput]);

	useEffect(() => {
		isMinimizedRef.current = isMinimized;
		if (!isMinimized) {
			setNewMsgBadgeCount(0);
		}
	}, [isMinimized]);

	const fetchMessages = () => {
		if (msgPage === msgTotalPages || !events.onGetMessages) {
			return;
		}

		setIsLoading(true);

		events.onGetMessages(groupData.id, msgPage + 1, 4)
			.then(data => {
				setIsLoading(false);
				if (!data.isError) {
					setMessages(messages => [...messages, ...data.value]);
					setMsgPage(data.page);
					setMsgTotalPages(data.totalPages);

					setIsLoading(false);
				}
			})
			.catch(() => setIsLoading(false));
	};

	const handleInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			handleSendNewMessage();
		}
	};

	const handleTitleClick = () => {
		if (!isMinimized) {
			if (membersWithoutUser.length === 1 && !isGameChat) {
				navigate(`/user/${membersWithoutUser[0].name}`);
			}
		} else {
			setIsMinimized(false);
		}
	};

	const handleClose = () => {
		closeChat(groupData.name);
	};

	const handleToggleMinimize = () => {
		setIsMinimized(isMinimized => !isMinimized);
	};

	const handleEmojiClick = (emoji: string) => {
		const myField = inputRef.current;
		if (!myField) {
			return;
		}

		if (myField.selectionStart || myField.selectionStart === 0) {
			const startPos = myField.selectionStart;
			const endPos = myField.selectionEnd || startPos;
			myField.value = myField.value.substring(0, startPos)
        + emoji
        + myField.value.substring(endPos, myField.value.length);
		} else {
			myField.value += emoji;
		}

		myField.focus();
	};

	const handleSendNewMessage = () => {
		if (!isLoading && inputRef.current?.value) {
			setIsLoading(true);
			events.onSendMessage(groupData.id, inputRef.current.value)
				.then(data => {
					setIsLoading(false);
					if (!data.isError) {
						setResetMsgInput(true);
					}
				});
		}
	};

	const onNewMessage = (message: MessageData) => {
		if (message.group.id === groupData.id) {
			if (isMinimizedRef.current) {
				setNewMsgBadgeCount(newMsgBadgeCount => newMsgBadgeCount + 1);
			}

			setMessages(messages => [message, ...messages]);
		}
	};

	const handleDeleteMessage = (messageId: number | string) => {
		if (!isLoading && events.onDeleteMessage) {
			events.onDeleteMessage(messageId);
		}
	};

	const onDeleteMessage = (message: MessageData) => {
		if (message.group.id === groupData.id) {
			setMessages(messages => messages.filter(msg => msg.id !== message.id));
		}
	};

	const getHeader = () => {
		return (
			<Stack direction="row" justifyContent="space-between" bgcolor={theme.palette.primary.dark} p={1}>
				<div className={classes.titleContainer} onClick={handleTitleClick}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<Badge
							overlap="circular"
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right"
							}}
							badgeContent={
								membersWithoutUser.length > 1 &&
										<Avatar
											src={membersWithoutUser[1].profilePicture}
											sx={{
												width: 24,
												height: 24,
												border: `2px solid ${theme.palette.background.paper}`,
											}}
										/>
							}
						>
							<Avatar src={membersWithoutUser[0].profilePicture} />
						</Badge>
						<Typography variant="h6">{title}</Typography>
					</Stack>
				</div>
				<Stack direction="row">
					<IconButton size="small" onClick={handleToggleMinimize}>
						{isMinimized ? <ExpandMoreIcon color="secondary" /> : <ExpandLessIcon color="secondary" />}
					</IconButton>
					{
						!isGameChat && (
							<IconButton size="small" onClick={handleClose}>
								<CloseIcon color="secondary" />
							</IconButton>
						)
					}
				</Stack>
			</Stack>
		);
	};

	const getBody = () => {
		return (
			<Stack id="msgContainer" direction="column-reverse" flexBasis="100%" sx={{ overflow: "auto" }}>
				<InfiniteScroll
					dataLength={messages.length}
					next={fetchMessages}
					hasMore={msgPage < msgTotalPages}
					loader={
						messages.length > 0 && !isGameChat && (
							<Stack alignItems="center" justifyContent="center" my={2}>
								<CircularProgress color="secondary" />
							</Stack>
						)
					}
					inverse
					scrollableTarget="msgContainer"
					style={{
						display: "flex",
						flexDirection: "column-reverse",
					}}
				>
					{
						messages.map((msg) => (
							<MessageItem
								key={msg.id}
								item={msg}
								isOwner={user?.name === msg.sender.name}
								isGameChat={isGameChat}
								onDelete={handleDeleteMessage}
							/>
						))
					}
				</InfiniteScroll>

			</Stack>
		);
	};

	const getFooter = () => {
		return (
			<Stack direction="row" bgcolor={theme.palette.primary.dark} p={1}>
				<TextField
					inputRef={inputRef}
					name="newMessage"
					label="Saississez votre message"
					variant="outlined"
					multiline
					color="secondary"
					fullWidth
					size="small"
					InputProps={{
						endAdornment: (
							<Stack direction="row" alignItems="center">
								<EmojiPicker onEmojiClick={handleEmojiClick} />
								<IconButton onClick={handleSendNewMessage}>
									<SendIcon color="secondary" />
								</IconButton>
							</Stack>
						),
						sx: {
							pr: 0,
							"& fieldset": {
								borderColor: theme.palette.secondary.main,
							}
						}
					}}
					onKeyUp={handleInputKeyUp}
				/>
			</Stack>
		);
	};

	return (
		<Paper elevation={3} className={classes.container}>
			<Box height={0}>
				<Badge
					className={classes.newMsgBadge}
					badgeContent={newMsgBadgeCount}
					color="error"
				/>
			</Box>
			<Stack height="100%">
				{getHeader()}
				{
					!isMinimized && (
						<>
							{getBody()}
							{getFooter()}
						</>
					)
				}
			</Stack>
		</Paper>
	);
}

export default Chat;