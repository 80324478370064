import React, { useState, useEffect } from "react";
import { Avatar, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "components/Page";
import LobbiesList from "./LobbiesList";
import { useLocation, useParams } from "react-router-dom";
import { GameService } from "services/appApi/GameService";
import { GameData } from "models/GameData";
import { Game } from "models/game/Game";
import Alert, { useAlertOption } from "components/Alert";
import Lobby from "./Lobby";

function GamePage () {
	const [game, setGame] = useState<GameData>();
	const [joinedGame, setJoinedGame] = useState<Game>();
	const {alertOptions, openAlert, closeAlert} = useAlertOption();

	const { gameName } = useParams();
	const location = useLocation();

	useEffect(() => {
		getGameInfos();
	}, [gameName]);

	useEffect(() => {
		if (location.state) {
			const { game, inviteRoom } = location.state as { game: Game, inviteRoom: string };
			if (game) {
				setJoinedGame(game);
				return;
			}

			if (inviteRoom) {
				handleJoinGame(inviteRoom);
			}
		}
	}, [location.state]);

	const getGameInfos = () => {
		if (gameName) {
			GameService.getGameByName(gameName)
				.then(data => {
					if (!data.isError) {
						setGame(data.value);
					}
				})
				.catch(() => {
					setGame({
						name: gameName,
						description: "Le jeu n'a pas été trouvé",
						icon: "",
					});
				});
		}
	};

	const handleOnCreateGame = (game: Game) => {
		setJoinedGame(game);
	};

	const handleJoinGame = (roomId: string) => {
		if (gameName) {
			GameService.joinGame(gameName, roomId)
				.then(data => {
					if (!data.isError) {
						setJoinedGame(data.value);
					}
				})
				.catch(err => {
					let errMsg = "Une erreur est survenue";
					if (typeof err === "string") {
						errMsg = err;
					}

					openAlert(errMsg, "error");
				});
		}
	};

	const handleUpdateGame = (game: Game | undefined) => {
		setJoinedGame(game);
	};

	const handleLeaveGame = () => setJoinedGame(undefined);

	return (
		<Page title={gameName} centerY>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					<Grid item sm={12} md={6}>
						<Stack direction="row" alignItems="center" spacing={2}>
							<Avatar
								src={game?.icon}
								sx={{
									width: 64,
									height: 64,
								}}
							/>
							<Typography variant="h6">{gameName}</Typography>
						</Stack>
						<Typography variant="body1" mt={2}>{game?.description}</Typography>
					</Grid>
					<Grid item sm={12} md={6}>
						{
							joinedGame ? (
								<Lobby
									game={joinedGame}
									onGameUpdated={handleUpdateGame}
									onBack={handleLeaveGame}
								/>
							) : (
								<LobbiesList
									gameName={gameName}
									onCreateGame={handleOnCreateGame}
									onJoinGame={handleJoinGame}
								/>
							)
						}
					</Grid>
				</Grid>
			</Container>
			<Alert options={alertOptions} onClose={closeAlert} />
		</Page>
	);
}

export default GamePage;