import React, { useState, useEffect } from "react";
import { Avatar, Box, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { UserService } from "services/appApi/UserService";
import { User } from "models/User";

interface IProps {
	loggedUserName: string;
	open: boolean;
	lobbyUsers: string[];
	onInvite: (players: string[]) => void;
	onClose: () => void;
}

function InviteDialog ({ loggedUserName, open, lobbyUsers, onInvite, onClose }: IProps) {
	const [friends, setFriends] = useState<User[]>([]);
	const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (open) {
			setIsLoading(true);
			UserService.getMyFriends()
				.then(data => {
					setIsLoading(false);
					if (!data.isError) {
						setFriends(
							data.value.filter(
								f => f.isConnected && !lobbyUsers.some(fn => f.name === fn)) ?? []
						);
					}
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	}, [loggedUserName, open]);

	const handleSelectFriend = (name: string, selected: boolean) => {
		if (selected) {
			setSelectedFriends([...selectedFriends, name]);
		} else {
			setSelectedFriends(selectedFriends.filter(f => f !== name));
		}
	};

	const handleInvite = () => {
		if (selectedFriends.length > 0) {
			onInvite(selectedFriends);
			onClose();

			setSelectedFriends([]);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogTitle>
				Inviter un ami
				<Typography>
					{friends.length > 0 ? "Sélectionnez des amis connectés :" : "Aucun ami connecté !"}
				</Typography>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormGroup>
					{
						!isLoading ? (
							friends.map(f => (
								<FormControlLabel
									key={f.name}
									control={
										<Checkbox
											color="secondary"
											checked={selectedFriends.some(fn => fn === f.name)}
											icon={<RadioButtonUncheckedIcon color="secondary" />}
											checkedIcon={<CheckCircleIcon color="secondary" />}
											onChange={(e, checked) => handleSelectFriend(f.name, checked)}
										/>
									}
									label={
										<Stack direction="row" alignItems="center" spacing={2}>
											<Avatar src={f.profilePicture} />
											<Typography>{f.name}</Typography>
										</Stack>
									}
									labelPlacement="start"
									sx={{
										justifyContent: "space-between",
									}}

								/>
							))
						) : (
							<Box alignSelf="center" mt={2}>
								<CircularProgress color="secondary" />
							</Box>
						)
					}
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					variant="contained"
					loading={isLoading}
					onClick={handleInvite}
				>
					Inviter
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}

export default InviteDialog;