import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import Page from "components/Page";
import TttGrid from "./TttGrid";
import { useUser } from "components/providers/AuthProvider";
import { TicTacToeSocketService } from "services/socket/Game/TicTacToe/TicTacToeSocketService";
import { GridPosition, TicTacToeGame } from "models/game/TicTacToe/TicTacToeModels";
import {  GameStatus } from "models/game/Game";
import { useManagedGame } from "hooks/GameHook";
import { IGamePlayProps } from "models/common/IGamePlayProps";
import ReplayGameDialog from "pages/Game/ReplayGameDialog";

function TicTacToePage ({ initialGame }: IGamePlayProps) {
	const game = useManagedGame(initialGame) as TicTacToeGame;
	const user = useUser();

	const isMyTurn = game?.turnPlayerName === user?.name;

	const handleGridClick = (position: GridPosition) => {
		if (isMyTurn && game?.status === GameStatus.PLAYING) {
			TicTacToeSocketService.playTurn(game.roomId, position);
		}
	};

	const getTitle = () => {
		if (!game) {
			return "";
		}

		if (game.status === GameStatus.FINISHED) {
			if (game.winner === user?.name) {
				return "Vous avez gagné, bravo !";
			}

			if (game.winner === null) {
				return "Match nul";
			}

			return "Vous avez perdu, dommage !";
		}

		if (isMyTurn) {
			return "C'est votre tour !";
		} else {
			return `C'est au tour de ${game.turnPlayerName} !`;
		}
	};

	return (
		<Page title="Morpion" centerY>
			<Container maxWidth="md">
				<Paper elevation={3} sx={{ mb: 2 }}>
					<Typography variant="h5" textAlign="center">{getTitle()}</Typography>
				</Paper>
				{
					game?.grid && (
						<TttGrid
							grid={game.grid}
							onClick={handleGridClick}
						/>
					)
				}
			</Container>
			<ReplayGameDialog
				gameName={game?.name}
				gameStatus={game?.status}
				roomId={game?.roomId}
			/>
		</Page>
	);
}

export default TicTacToePage;