import React, { useState } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Alert, { AlertOptions } from "components/Alert";
import { AuthService } from "services/appApi/AuthService";

function ChangePswForm () {
	const [oldPsw, setOldPsw] = useState("");
	const [newPsw, setNewPsw] = useState("");
	const [confirmPsw, setConfirmPsw] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [alertOptions, setAlertOptions] = useState<AlertOptions>();

	const handleChangeOldPsw = (e: React.ChangeEvent<HTMLInputElement>) => setOldPsw(e.target.value);
	const handleChangeNewPsw = (e: React.ChangeEvent<HTMLInputElement>) => setNewPsw(e.target.value);
	const handleChangeConfirmPsw = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPsw(e.target.value);

	const handleCloseAlert = () => {
		setAlertOptions(options => ({
			text: options?.text || "",
			type: options?.type || "info",
			open: false,
		}));
	};

	const handleSubmit = () => {
		if (oldPsw && newPsw && confirmPsw) {
			setIsLoading(true);
			AuthService.changePassword(oldPsw, newPsw, confirmPsw)
				.then(data => {
					setIsLoading(false);
					if (!data.isError) {
						setAlertOptions({
							text: "Votre mot de passe a bien été modifié.",
							type: "success",
							open: true,
						});

						setOldPsw("");
						setNewPsw("");
						setConfirmPsw("");
					} else {
						setAlertOptions({
							text: data.message,
							type: "error",
							open: true,
						});
					}
				})
				.catch(err => {
					setIsLoading(false);

					let message = "Une erreur est survenue.";
					if (err && typeof err === "string") {
						message = err;
					}
					setAlertOptions({
						text: message,
						type: "error",
						open: true,
					});
				});
		}
	};

	return (
		<React.Fragment>
			<Stack spacing={2}>
				<TextField
					type="password"
					variant="outlined"
					color="secondary"
					label="Ancien mot de passe"
					fullWidth
					autoFocus
					autoComplete="new-password"
					value={oldPsw}
					onChange={handleChangeOldPsw}
				/>
				<TextField
					type="password"
					name="psw"
					variant="outlined"
					color="secondary"
					label="Nouveau mot de passe"
					fullWidth
					value={newPsw}
					onChange={handleChangeNewPsw}
				/>
				<TextField
					type="password"
					name="pswConfirm"
					variant="outlined"
					color="secondary"
					label="Confirmez le nouveau mot de passe"
					fullWidth
					value={confirmPsw}
					onChange={handleChangeConfirmPsw}
				/>
				<Box alignSelf="flex-end" pt={2}>
					<LoadingButton
						variant="contained"
						color="primary"
						loading={isLoading}
						disabled={!oldPsw || !newPsw || !confirmPsw}
						onClick={handleSubmit}
					>
                    Sauvegarder
					</LoadingButton>
				</Box>
			</Stack>
			<Alert
				options={alertOptions}
				onClose={handleCloseAlert}
			/>
		</React.Fragment>
	);
}

export default ChangePswForm;