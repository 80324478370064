import React from "react";
import { Box, Divider, Paper, Skeleton, Stack } from "@mui/material";
import { Post } from "models/Post";
import PostItem from "./PostItem";

interface IProps {
	emptyPost: Post;
	showEmptyPost: boolean;
}

function PostPlaceholder ({ emptyPost, showEmptyPost }: IProps) {
	if (showEmptyPost && emptyPost) {
		return (
			<PostItem
				post={emptyPost}
				localUserName={""}
				readonly={true}
				onToggleLike={() => 1}
				onNewComment={() => 1}
				onDeleteComment={() => 1}
				onDeletePost={() => 1}
			/>
		);

	}

	return (
		<Paper>
			<Stack direction="column">
				<Stack direction="row" spacing={3} alignItems="center">
					<Skeleton variant="circular" width={40} height={40} />
					<Skeleton variant="text" width={200} height={20} />
				</Stack>
				<Box my={1} sx={{ width: "100%" }}>
					<Divider />
				</Box>
				<Stack direction="column" spacing={2}>
					<Box>
						<Skeleton variant="text" width="80%" height={20} />
						<Skeleton variant="text" width="70%" height={20} />
						<Skeleton variant="text" width="75%" height={20} />
					</Box>
					<Skeleton variant="rectangular" width="100%" height={200} />
				</Stack>
			</Stack>
		</Paper>
	);
}

export default PostPlaceholder;