import { useChatList } from "components/providers/ChatListProvider";
import { usePopupAlert } from "components/providers/PopupAlertProvider";
import { Game } from "models/game/Game";
import { User } from "models/User";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GameService } from "services/appApi/GameService";
import { GameSocketService } from "services/socket/Game/GameSocketService";
import { SocketService } from "services/socket/SocketService";

/**
 * Hook to provide game management
 * 
 * @param {Game} initialGame The initial game
 * @returns {Game} Managed game
 */
export function useManagedGame (initialGame?: Game) {
	const [game, setGame] = useState(initialGame);
	const popupAlert = usePopupAlert();
	const navigate = useNavigate();
	const { openChat, closeChat, setContainerRightOffset } = useChatList();

	const sendChatMessage = (groupId: string | number, message: string) => {
		if (!game) {
			throw new Error("Game is null");
		}
		return GameService.sendChatMessage(game.name, groupId as string, message);
	};

	useEffect(() => {
		return () => {
			if (game) {
				GameService.leaveGame(game.name, game.roomId);
			}
		};
	}, []);

	useEffect(() => {
		const unsubOnError = SocketService.onError(onSocketError);
		const unsubGameUpdate = GameSocketService.onGameUpdate(onGameUpdate);
		const unsubGamestoppedWithError = GameSocketService.onGameStopedWithError(onGameStopedWithError);

		if (game) {
			setContainerRightOffset(10);
			openChat({
				title: game.name,
				groupData: {
					id: game.roomId,
					name: game.name,
					members: []
				},
				members: game.players.map(player => {
					const user: User = {
						name: player.name,
						profilePicture: player.profilePicture ?? "",
						banner: "",
						description: "",
					};

					return user;
				}),
				events: {
					onSendMessage: sendChatMessage,
				},
				isGameChat: true
			});
		}

		return () => {
			unsubOnError();
			unsubGameUpdate();
			unsubGamestoppedWithError();

			if (game) {
				closeChat(game?.name);
			}
		};
	}, []);

	const onSocketError = (error: string) => {
		popupAlert.open({
			title: "Une erreur est survenue",
			message: error,
			color: "danger",
		});
	};

	const onGameUpdate = (game: Game) => {
		setGame(game);
	};

	const handleGoToGamePage = () => {
		navigate(`/game/${game?.name}`);
	};

	const onGameStopedWithError = (error: string) => {
		popupAlert.open({
			title: "Le jeu a été arrêté",
			message: error,
			color: "danger",
			action: {
				label: "OK",
				onClick: handleGoToGamePage
			}
		});
	};

	return game;
}