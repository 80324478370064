import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { GridPosition, GridSymbol } from "models/game/TicTacToe/TicTacToeModels";

const useStyles = makeStyles<Theme, {gh?: number}>((theme: Theme) => ({
	root: {
		"& .MuiGrid-item": {
			position: "relative",
			border: `1px solid ${theme.palette.divider}`,
			paddingTop: props => props.gh,
		},
		"& svg": {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: "100%",
			height: "100%",
		}
	}
}));

interface IProps {
	grid: GridSymbol[][];
	onClick: (position: GridPosition) => void;
}

export default function TttGrid ({ grid, onClick }: IProps) {
	const [gh, setGh] = useState(0);

	const classes = useStyles({ gh });

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleResize = () => {
		const newHeight = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight;
		setGh(newHeight ? newHeight * 0.2 : 0);
	};

	const getSymbol = (value: GridSymbol) => {
		switch (value) {
			case 0:
				return <CircleOutlinedIcon color="secondary" />;

			case 1:
				return <CloseIcon color="secondary" />;

			default:
				return "";
		}
	};

	const getGridItem = (position: GridPosition) => {
		const handleClick = () => onClick(position);

		return (
			<Grid item xs={4}>
				<Box
					sx={{
						cursor: "pointer",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "100%",
						height: "100%",
					}}
					onClick={handleClick}
				/>
				{getSymbol(grid[position.x][position.y])}
			</Grid>
		);
	};

	return (
		<Paper className={classes.root} elevation={2}>
			<Grid container>
				{/* Line 1 */}
				{getGridItem({
					x: 0,
					y: 0
				})}
				{getGridItem({
					x: 1,
					y: 0
				})}
				{getGridItem({
					x: 2,
					y: 0
				})}

				{/* Line 2 */}
				{getGridItem({
					x: 0,
					y: 1
				})}
				{getGridItem({
					x: 1,
					y: 1
				})}
				{getGridItem({
					x: 2,
					y: 1
				})}

				{/* Line 3 */}
				{getGridItem({
					x: 0,
					y: 2
				})}
				{getGridItem({
					x: 1,
					y: 2
				})}
				{getGridItem({
					x: 2,
					y: 2
				})}

			</Grid>
		</Paper>
	);
}
