import React, { useState } from "react";
import { Avatar, Box, CircularProgress, Menu, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserService } from "services/appApi/UserService";
import { User } from "models/User";

function UserSearchBar () {
	const [users, setUsers] = useState<User[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();

	const handleSearch = (userName: string) => {
		if (!userName) {
			setUsers([]);
			return;
		}

		setIsLoading(true);
		setUsers([]);

		UserService.searchUsers(userName)
			.then(data => {
				if (!data.isError) {
					setUsers(data.value);
				}
				setIsLoading(false);
			})
			.catch(() => setIsLoading(false));
	};

	const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAnchorEl(event.currentTarget);
		handleSearch(event.target.value);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<TextField
				color="secondary"
				variant="outlined"
				size="small"
				type="search"
				label="Chercher un utilisateur..."
				aria-controls="search-user-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onChange={handleTextChange}
			/>
			<Menu
				anchorEl={anchorEl}
				id="search-user-menu"
				open={open}
				autoFocus={false}
				disableAutoFocus
				disableAutoFocusItem
				PaperProps={{
					sx: {
						width: anchorEl?.clientWidth,
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						}
					},
				}}
				onClose={handleClose}
				onClick={handleClose}
			>
				{
					isLoading && (
						<Box display="flex" justifyContent="center">
							<CircularProgress color="secondary" />
						</Box>
					)
				}
				{
					users.map((user, inx) => (
						<MenuItem key={inx} onClick={() => navigate(`/user/${user.name}`)}>
							<Avatar src={user.profilePicture} /> {user.name}
						</MenuItem>
					))
				}
			</Menu>
		</React.Fragment>
	);
}

export default UserSearchBar;